export const icon = {
    className: "w-5 h-5 text-[#39ACE4] m-1 cursor-pointer"
};

export const REGEX = {
    EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PHONE: /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/,
    WHOLE_NUMBER: /^\d+$/,
    POSITIVE_GRT_ZERO: /^[1-9][0-9]*$/,
    ALPHA: /^[A-Za-z]+$/,
    FRACTION: /^\d*\.?\d*$/
};
