export const SIDE_WALL_TYPES = [
  {
    productId: "Plain sidewall",
    productName: "Plain sidewall",
    productPrice: 0
  },
  {
    productId: "Corrugated sidewall",
    productName: "Corrugated sidewall",
    productPrice: 0
  },
  {
    productId: "Not applicable",
    productName: "Not applicable",
    productPrice: 0
  }
];

export const INITIAL_FORM_VALUE = {
  additionalReinforcement: {
    productId: "",
    productName: ""
  },
  application: "",
  axlesMake: {
    productId: "",
    productName: ""
  },
  axlesType: {
    productId: "",
    productName: ""
  },
  brakeChamber: {
    productId: "",
    productName: ""
  },
  brakeKit: {
    productId: "",
    productName: ""
  },
  coilLashing: {
    productId: "",
    productName: ""
  },
  electrical: "",
  floorSheet: {
    productId: "",
    productName: ""
  },
  khutlaPillarDetails: {
    productId: "",
    productName: ""
  },
  khutlaPocket: {
    productId: "",
    productName: ""
  },
  kingpin: {
    productId: "",
    productName: ""
  },
  landingLeg: {
    productId: "",
    productName: ""
  },
  mudGuard: {
    productId: "",
    productName: ""
  },
  painting: "",
  reflectorYellow: "",
  rupd: "",
  sideMakerTailLamp: "",
  spareWheelCarrierBkt: "",
  spraySuppressor: "",
  stickers: "",
  supd: "",
  suspensionMake: {
    productId: "",
    productName: ""
  },
  suspensionType: {
    productId: "",
    productName: ""
  },
  suspensioncombination: {
    productId: "",
    productName: ""
  },
  tieDownHook: {
    productId: "",
    productName: ""
  },
  toolBox: "",
  trailerDescription: "",
  trailerType: {
    productId: "",
    productName: ""
  },
  truckModel: {
    productId: "",
    productName: ""
  },
  twistLock: {
    productId: "",
    productName: ""
  },
  tyre: {
    productId: "",
    productName: "",
    radialNylon: "",
  },
  wheelRim: {
    productId: "",
    productName: ""
  },
  note: "",
  warrenty: 0,
  additionalInformation: "",
  additionalInformation1: "",
  sideWallType: {
    productId: "",
    productName: ""
  }
};


export const PAYLOAD = {
    "additionalInformation": "Test",
    "additionalInformation1": "Test",
    "additionalReinforcement": [
      {
        "productId": "",
        "productName": "",
        "productPrice": 0,
        "selected": true
      }
    ],
    "application": "Coil",
    "axlesMake": {
      "make": [
        {
          "productId": "593719f6-94e5-4048-8373-f89fb3e48617",
          "productName": "16T Non ABS Axle-3 No's,18T Tandem Suspension,14T Air Suspension with front lift",
          "productPrice": 0,
          "selected": true
        }
      ],
      "type": [
        {
          "productId": "7cdabda1-b530-4059-889b-cd960ff339d4",
          "productName": "York",
          "productPrice": 0,
          "selected": true
        }
      ]
    },
    "brakeChamber": [
      {
        "productId": "bad9d084-12ac-474f-a775-ac9de5f47657",
        "productName": "6 No's",
        "productPrice": 0,
        "selected": true
      }
    ],
    "brakeKit": [
      {
        "productId": "0691601e-ac6e-43d3-9f56-87092a18e249",
        "productName": "Wabco ABS",
        "productPrice": 0,
        "selected": true
      }
    ],
    "coilLashing": [
      {
        "productId": "",
        "productName": "",
        "productPrice": 0,
        "selected": true
      }
    ],
    "electrical": "yes",
    "floorSheet": [
      {
        "productId": "a1487c28-89a0-4dce-a769-bd2c0b5a2bb9",
        "productName": "3.15 Ch.Plate",
        "productPrice": 0,
        "selected": true
      }
    ],
    "khutlaPillarDetails": [
      {
        "productId": "",
        "productName": "",
        "productPrice": 0,
        "selected": true
      }
    ],
    "khutlaPocket": [
      {
        "productId": "",
        "productName": "",
        "productPrice": 0,
        "selected": true
      }
    ],
    "kingpin": [
      {
        "productId": "5c9b9fd2-89de-4d5a-8729-dd0bf3795527",
        "productName": "Jost Bolted",
        "productPrice": 0,
        "selected": true
      }
    ],
    "landingLag": [
      {
        "productId": "5a3d06e5-283c-47e7-94ee-00890a4dae01",
        "productName": "York Two Stage Gear 28T",
        "productPrice": 0,
        "selected": true
      }
    ],
    "mudGuard": [
      {
        "productId": "ee00074d-6c51-458e-a23a-fbf5bc5f1698",
        "productName": "Steel make",
        "productPrice": 0,
        "selected": true
      }
    ],
    "note": "23-05-2024",
    "orderGenerateFlag": false,
    "painting": "yellow",
    "reflectorYellow": "yes",
    "rupd": "yes",
    "sideMakerTailLamp": "yes",
    "sideWallType": [
      {
        "productId": "Not applicable",
        "productName": "Not applicable",
        "productPrice": 0,
        "selected": true
      }
    ],
    "spareWheelCarrierBkt": "yesy",
    "spraySuppressor": "yesy",
    "status": "Initial",
    "stickers": "yesy",
    "supd": "yes",
    "suspension": {
      "combination": [
        {
          "productId": "8eadf6f3-794c-411a-ba2d-4a0f3effe4d4",
          "productName": "54T",
          "productPrice": 0,
          "selected": true
        }
      ],
      "make": [
        {
          "productId": "1f7d565a-aea0-4c8c-9e05-d8a0d7262730",
          "productName": "York",
          "productPrice": 0,
          "selected": true
        }
      ],
      "type": [
        {
          "productId": "ca8dd024-ba40-4aa6-8a0c-16847a3d323a",
          "productName": "16T",
          "productPrice": 0,
          "selected": true
        }
      ]
    },
    "tieDownHook": [
      {
        "productId": "",
        "productName": "",
        "productPrice": 0,
        "selected": true
      }
    ],
    "toolBox": "yes",
    "trailerDescription": "Na",
    "trailerType": [
      {
        "productId": "00ae199d-b79c-4c51-9ade-3104c245a723",
        "productName": "40 ft 2A  Flat Bed Trailer",
        "productPrice": 0,
        "selected": true
      }
    ],
    "truckModel": [
      {
        "productId": "bbfae9ed-4cef-433c-a44a-458d53d0799a",
        "productName": "TATA 5530",
        "productPrice": 0,
        "selected": true
      }
    ],
    "twistLock": [
      {
        "productId": "97b454ab-a286-490e-82af-9766d71d744e",
        "productName": "12 No's",
        "productPrice": 0,
        "selected": true
      }
    ],
    "tyre": {
      "make": [
        {
          "productId": "b7ab9ec9-ad72-4fc4-9701-14a83a9f633c",
          "productName": "Apollo",
          "productPrice": 0,
          "selected": true
        }
      ],
      "radialNylon": true,
      "ribLug": true
    },
    "warrenty": 0,
    "wheelRim": [
      {
        "productId": "37464805-d5da-464a-9a04-c06aa936732c",
        "productName": "KALYNAI",
        "productPrice": 0,
        "selected": true
      }
    ]
};
