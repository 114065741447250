const USERMODEL = {
    familyName: "",
    givenName: "",
    passCode: "",
    contactMedium: {
        email: "",
        phoneNumber: ""
    },
    roleId: [],
    userId: ""
};

const ROLEMODEL = {
    name: "",
    description: "",
};

const PASSWORDMODEL = {
    password: "",
};

export const INITIAL_USER_FORM_VALUE = USERMODEL;

export const USER_ERROR_MESSAGE_VALUES = { ...USERMODEL, roleId: "" };

export const USER_REQUIRED_FIELDS = ["familyName", "givenName", "passCode", "contactMedium.email", "contactMedium.phoneNumber", "roleId", "userId"];

export const INITIAL_ROLE_FORM_VALUE = ROLEMODEL;

export const ROLE_ERROR_MESSAGE_VALUES = ROLEMODEL;

export const ROLE_REQUIRED_FIELDS = ["name", "description"];

export const INITIAL_PWD_FORM_VALUE = PASSWORDMODEL;

export const PWD_ERROR_MESSAGE_VALUES = PASSWORDMODEL;

export const PWD_REQUIRED_FIELDS = ["password"];