// TableCustomFilter.js
import React from 'react';
import DateRangePicker from '../DateRangePicker/DateRangePicker';
import SearchInput from '../SearchInput/SearchInput';

const TableCustomFilter = ({ filterDataFunction, getSelectedDates }) => {
    return (
        <div className="flex items-center">
            {getSelectedDates && <DateRangePicker callBackFunction={getSelectedDates} />}
            <SearchInput filterDataFunction={filterDataFunction} />
        </div>
    );
};

export default TableCustomFilter;
