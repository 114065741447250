
import React, { useEffect, useState } from 'react';
import { isEmpty } from "lodash";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Blocks } from "react-loader-spinner";
import { TrashIcon, PencilIcon, EyeIcon  } from "@heroicons/react/24/solid";

import Button from "@/components/Button";
import PaginationTable from "@/components/PaginationTable";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal";
import ContentHeader from "../ContentHeader";

import { useAuth } from "@/hooks/useAuth";
import { APIService } from "@/services";
import { orderApi } from "@/utils/API";
import { addSerialNumbersToList } from "@/utils/CustomFunctions";
import { VIEW_WORK_ORDER_LIST_HEADER } from "./Headers";
import { HandleExport } from "@/utils/HandleExport";
import { icon } from "@/utils/AppConstants";
import { APP_URLS } from "@/routes";

const headerButton = {
    showAdd: true,
    showExportBtn: true
};

const ViewWorkOrderList = () => {
    const navigate = useNavigate();
    const [queryParams] = useSearchParams();
    const { user: auth } = useAuth();

    const parentOrderId = queryParams.get("workOrderId");
    const quotationId = queryParams.get("quotationId");

    const isAdmin = auth.roleList.indexOf("1") !== -1;
    const [workOrderList, setWorkOrderList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState("");
    const [isListChanged, setIsListChanged] = useState(false);

    const toggleDeletePopup = () => {
        setIsOpenDeleteModal(!isOpenDeleteModal);
    };

    const getSubOrders = async () => {
        setIsLoading(true);
        const url = isAdmin ? orderApi.getSuborders(parentOrderId) : orderApi.getSuborders(parentOrderId);
        //orderApi.getSubordersByUsername(parentOrderId, auth.username);
        const response = await APIService.get(url);
        const updatedList = await addSerialNumbersToList(response.orderList);
        setWorkOrderList(updatedList);
        setIsLoading(false);
    };

    useEffect(() => {
        getSubOrders();
    }, [isListChanged]);

    const handleDelete = async (id) => {
        setIsLoading(true);
        const deleteUrl = `${orderApi.delete}${id}`;
        const response = await APIService.delete(deleteUrl);
        setIsOpenDeleteModal(!isOpenDeleteModal);
        setIsListChanged(!isListChanged);
        setIsLoading(false);
    };

    const handleActionClick = async (action, rowData) => {
        if (action === "delete") {
            const id = rowData?.id;
            setIsOpenDeleteModal(!isOpenDeleteModal);
            setSelectedId(id);
        } else if (action === "edit") {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.EDIT_WO}?quotationId=${quotationId}&workOrderId=${rowData.id}&orderType=1`);
        } else if (action === "view") {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.VIEW_WO}?quotationId=${quotationId}&workOrderId=${rowData.id}&orderType=1&mode=view`);
        }
    };

    const columnsArray = [
        ...VIEW_WORK_ORDER_LIST_HEADER,
        {
            Header: "Action",
            accessor: "action",
            width: 5,
            Cell: ({ value, row }) => {
                return (
                    <div className="text-[25] flex justify-center text-[#39ACE4]">
                        <EyeIcon {...icon} title="View Details" onClick={() => handleActionClick("view", row.original)} />
                        { isAdmin && <PencilIcon {...icon} title="Edit Order" onClick={() => handleActionClick("edit", row.original)} /> }
                        {/* { isAdmin && <TrashIcon {...icon} title="Delete Order" onClick={() => handleActionClick("delete", row.original)} /> } */}
                    </div>
                )
            },
        },
    ];

    return (
        <>
            <ContentHeader
                title="Work Order"
                description="Sales Department / Work Order"
                showHeader={true}
                showDatePicker={false}
                showSearchInput={false}
                showButton={false}
                btnToShow={headerButton}
            />
            <div className="m-4 flex flex-col rounded h-auto bg-white">
                {isOpenDeleteModal && selectedId && (
                    <DeleteConfirmationModal
                        handleDelete={handleDelete}
                        isOpen={isOpenDeleteModal}
                        togglePopup={toggleDeletePopup}
                        id={selectedId}
                    />
                )}

                <div className="flex p-2.5 justify-between items-center">
                    <Button text="Back" textColor="white" bgColor="[#39ACE4]" onClick={() => navigate(-1)} />
                    { !isEmpty(workOrderList) &&
                        <div>
                            <Button text="Export to Excel" icon="FaDownload" textColor="white" bgColor="[#39ACE4]" onClick={() => { HandleExport(columnsArray, workOrderList, "WorkOrderList") }} />
                        </div>
                    }
                </div>
                {isLoading ? (
                    <div className="flex items-center justify-center">
                        <Blocks
                            height="80"
                            width="80"
                            color="#4fa94d"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            visible={true}
                        />
                    </div>
                ) : (
                    workOrderList && <PaginationTable columnsData={columnsArray} rowData={workOrderList} />
                )}
            </div>
        </>
    )
};

export default ViewWorkOrderList;