import { 
    HomeIcon,
    UserIcon,
    UserPlusIcon,
    Squares2X2Icon,
    RectangleStackIcon,
    ShieldCheckIcon,
    CalculatorIcon,
    UserCircleIcon,
    DocumentIcon,
    Cog8ToothIcon,
    PowerIcon,
    DocumentDuplicateIcon,
    ShoppingCartIcon,
    QueueListIcon,
    SwatchIcon
} from "@heroicons/react/24/solid";
import { FaChevronRight } from "react-icons/fa";
import { GiPencilRuler } from "react-icons/gi";
import { MdSettingsSuggest, MdCategory } from "react-icons/md";
import { APP_URLS } from ".";

const icon = { className: "w-5 h-5 text-inherit" };

const SideMenuRoutes = [
    {
        layout: "dashboard",
        pages: [
            {
                icon: <Squares2X2Icon {...icon} />,
                name: "Dashboard",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.HOME}`,
                allowedTo: "Admin Roles"
            },
            {
                icon: <Squares2X2Icon {...icon} />,
                name: "User & Roles",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.USR_ACCT}`,
                allowedTo: "Admin Roles"
            },
            {
                icon: <UserIcon {...icon} />,
                name: "Sales Department",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.SALES_ENQ_LIST}`,
                allowedTo: "Sales Department",
                nestedRoutes: [
                    {
                        icon: <FaChevronRight {...icon} />,
                        name: "Enquiry",
                        path: `${APP_URLS.SALES_ENQ_LIST}`,
                        allowedTo: "Enquire",
                    },
                    {
                        icon: <FaChevronRight {...icon} />,
                        name: "Work Order",
                        path: `${APP_URLS.SALES_WO_LIST}`,
                        allowedTo: "Work Order",
                    },
                ]
            },
            {
                icon: <GiPencilRuler {...icon} />,
                name: "Design Department",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.DESN_DEPT}`,
                allowedTo: "Design Department",
            },
            {
                icon: <RectangleStackIcon {...icon} />,
                name: "Production Department",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.PROD_DEPT}`,
                allowedTo: "Production Department"
            },
            {
                icon: <ShieldCheckIcon {...icon} />,
                name: "Quality and Dispatch",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.QLTY_DISPATCH}`,
                allowedTo: "Production Department"
            },
            {
                icon: <CalculatorIcon {...icon} />,
                name: "Account Department",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.ACCT_DEPT}`,
                allowedTo: "Admin Roles"
            },
            {
                icon: <UserCircleIcon {...icon} />,
                name: "Admin Approval",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.ADMIN_WO_LIST}`,
                allowedTo: "Admin Roles",
                nestedRoutes: [
                    {
                        icon: <FaChevronRight {...icon} />,
                        name: "Work Order",
                        path: `${APP_URLS.ADMIN_WO_LIST}`,
                        allowedTo: "Admin Roles",
                    },
                ]
            },
            // {
            //     icon: <DocumentDuplicateIcon {...icon} />,
            //     name: "Quotation Templates",
            //     path: `${APP_URLS.APP_ROOT}/${APP_URLS.ADMIN_QUO_TEMP_LIST}`,
            //     allowedTo: "Admin Roles"
            // },
            {
                icon: <DocumentIcon {...icon} />,
                name: "Reports",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.REPORT}`
            },
            {
                icon: <ShoppingCartIcon {...icon} />,
                name: "Masters",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.CATEGORY}`,
                allowedTo: "Admin Roles",
                nestedRoutes: [
                    {
                        icon: <QueueListIcon {...icon} />,
                        name: "Category",
                        path: `${APP_URLS.CATEGORY}`,
                        allowedTo: "Category",
                    },
                    {
                        icon: <SwatchIcon {...icon} />,
                        name: "Products",
                        path: `${APP_URLS.PRODUCT}`,
                        allowedTo: "Product",
                    },
                ]
            },
            {
                icon: <Cog8ToothIcon {...icon} />,
                name: "Settings",
                path: `${APP_URLS.APP_ROOT}/${APP_URLS.SETTGS}`,
                allowedTo: "Admin Roles",
            },
            // {
            //     icon: <PowerIcon {...icon} />,
            //     name: "Logout",
            //     path: "",
            //     allowedTo: "All",
            // },
        ],
    },
];

export default SideMenuRoutes;
