import React, { useState, useEffect, useRef } from 'react';
import { FaAngleDown } from 'react-icons/fa';

const OrderDropdown = ({ options, required = false, selectedOption = '', onOptionSelect, label, parentClass = "", id, height = '200px', disabled = false, alignment = "text-left" }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleOptionClick = (option) => {
        onOptionSelect(option, id);
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const selectedOptionObject = options.find((option) => option.productId === selectedOption?.productId);

    return (
        <div className={`relative ${parentClass}`} ref={dropdownRef}>
            <label className={`block text-base text-black text-[12px] ${alignment}`}>{label} {required && <span className="text-red-500 text-[12px] font-extrabold">*</span>}</label>
            <button
                className={`whitespace-nowrap overflow-hidden inline-flex items-center justify-between w-full px-4 py-2 text-[12px] bg-white rounded border border-gray-400 ${disabled ? 'border-gray-400 text-gray-400' : 'border-gray-400'}`}
                onClick={() => !disabled && setIsOpen(!isOpen)}
                disabled={disabled}
            >
                <span className='flex overflow-hidden w-[93%]' title={selectedOptionObject ? selectedOptionObject.productName : 'Please Select'}>{selectedOptionObject ? selectedOptionObject.productName : 'Please Select'}</span>
                <FaAngleDown className="ml-2" />
            </button>
            {isOpen && (
                <div className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg">
                    <ul className="py-2" style={{ maxHeight: '200px', overflow: 'auto' }}>
                        {options.map((option, index) => (
                            <li
                                key={index}
                                className="px-4 py-2 hover:bg-gray-100"
                                style={{ height: '2rem', overflow: 'hidden', whiteSpace: 'nowrap' }}
                                title={option?.productName}
                            >
                                <button
                                    className={`whitespace-nowrap overflow-hidden  w-full text-left focus:outline-none text-[12px] ${option.id === selectedOption ? 'font-semibold' : ''}`}
                                    onClick={() => handleOptionClick(option, id)}
                                    id={id}
                                >
                                    <span className='flex overflow-hidden w-[93%]' >{option.productName}</span>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>

    );
};

export default OrderDropdown;
