export const ENQUIRY_FORM_INITIAL_STATE = {
    competitor: "",
    contactMedium: {
        email: "",
        phoneNumber: ""
    },
    description: "",
    firstName: "",
    lastName: "",
    leadBy: "",
    location: "",
    quantity: "",
    transporterName: "",
    typeOfTrailer: "",
    length:"",
    axleType: "",
    width: "",
    height: "",
    status: "",
};

export const ENQUIRY_FORM_REQUIRED_FIELDS = [
    "firstName",
    "lastName",
    "contactMedium.email",
    "contactMedium.phoneNumber",
    "transporterName",
    "typeOfTrailer",
    "length",
    "axleType",
    "width",
    "height",
    "location",
    "quantity"
];

export const ENQUIRY_FORM_ERRORMSG_FIELDS = {
    firstName: "",
    lastName: "",
    contactMedium: {
        email: "",
        phoneNumber: ""
    },
    transporterName: "",
    typeOfTrailer: "",
    length: "",
    axleType: "",
    width: "",
    height: "",
    location: "",
    quantity: ""
};


export const ENQUIRY_STATUS = [
    { id: 1, productId: "Initial", name: "Initial" },
    { id: 1, productId: "Lost", name: "Lost" },
    { id: 2, productId: "Close", name: "Close" },
    { id: 3, productId: "Postpone", name: "Postpone" }
];
