export const INITIAL_FORM_VALUE = {
    address : {
        city: "",
        country: "",
        fullAddress: "",
        locality: "",
        pinCode: "",
        state: "",
        streetName: ""
    },
    bankDetails : {
        accountNumber: "",
        bankName: "",
        branchName: "",
        isfccode: "",
        upiID: ""
    },
    contactMedium : {
        email: "",
        phoneNumber: ""
    },
    gstDetails: {
        cgst: 0,
        igst: 0,
        prefix: "",
        sgst: 0
    },
    gstNum: "",
    hsnOrSACNum: "",
    organizationName: "",
    organizationSubtitle: "",
    panNum: "",
    status: "",
    websiteURL: ""
};

export const REQUIRED_FIELDS = [
    "organizationName",
    "organizationSubtitle"
];

export const ERROR_MESSAGE_VALUES = {
    organizationName: "",
    organizationSubtitle: "",
};