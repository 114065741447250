import PropTypes from 'prop-types';
import FaIcon from '../FaIcons/FaIcons'

const Button = ({ text, icon = '', textColor, bgColor, onClick, disabled = false }) => {
    return (
        <button
            onClick={onClick}
            className={`justify-center text-${textColor} bg-${bgColor} hover:ring-${bgColor}-dark ring-opacity-50 rounded py-2 px-4 gap-2 flex items-center text-[14px]`}
            disabled={disabled}
        >
            {icon && <FaIcon icon={icon} className="mr-2" />}
            {text}
        </button>
    );
};

Button.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.string,
    textColor: PropTypes.string.isRequired,
    bgColor: PropTypes.string.isRequired,
    onClick: PropTypes.func
};

export default Button;
