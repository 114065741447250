import React, { useEffect, useState } from "react";
import axios from "axios";
import { get } from "lodash";
import { toast } from "react-toastify";
import { HiOutlineEye } from "react-icons/hi";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

import { loginServiceApi } from "@/utils/API";
import { 
    INITIAL_OTP_FORM_VALUE,
    OTP_REQUIRED_FIELDS,
    OTP_ERROR_MESSAGE_VALUES,
    DEFAULT_AUTH_CONFIG
} from "../constant";

const ValidatePasswordOTP = ({ onStepChanged, username }) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isResentOtp, setIsResentOtp] = useState(false);
    const [formValue, setFormValue] = useState(INITIAL_OTP_FORM_VALUE);
    const [errorMessages, setErrorMessages] = useState(OTP_ERROR_MESSAGE_VALUES);

    const handleChange = e => {
        setFormValue({
            ...formValue,
            [e.target.name]: e.target.value
        })
    };

    const resetForm = () => {
        setFormValue({
            otp: ""
        });
    }

    const resentOTP = async () => {
        setLoading(true);
        let url = `${loginServiceApi.resetPasswordSendOTP}?userID=${username}`;
        let response = await axios.patch(url, {}, DEFAULT_AUTH_CONFIG);
        setLoading(false);
        setIsResentOtp(false);

        if (response.status === 200) {
            toast.success("OTP is sent to registered email");
        } else {
            toast.success("Error, Please try again!");
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newErrorMessages = {};
        const requiredFields = OTP_REQUIRED_FIELDS;
        requiredFields.forEach((field) => {
            if (!get(formValue, field)) {
                newErrorMessages[field] = "This field is required.";
            } else {
                newErrorMessages[field] = "";
            }
        });

        setErrorMessages(newErrorMessages);
        if (Object.values(newErrorMessages).some((errorMsg) => errorMsg !== "")) {
            return;
        };
        
        try {
            setLoading(true);
            let url = `${loginServiceApi.resetPasswordValidateOTP}?userID=${username}&otp=${formValue.otp}`;
            let response = await axios.patch(url, {}, DEFAULT_AUTH_CONFIG);
            setLoading(false);

            toast.success("Valid OTP");
            onStepChanged("SET_PASSWORD", username);
        } catch (error) {
            setLoading(false);
            toast.error("Invalid OTP");
            setIsResentOtp(true);
            resetForm();
        }
    };

    return (
        <>
            <h1 className="text-white text-3xl font-semibold text-center mb-2">Validate OTP</h1>
            <form className="space-y-6">
                <div className="flex items-center rounded-md bg-white border border-gray-300">
                    <span className="inline-flex items-center justify-center rounded-l-md px-2">
                        <HiOutlineEye className="w-6 h-6 text-gray-400" />
                    </span>
                    <input
                        type="otp"
                        id="otp"
                        name="otp"
                        className="flex-grow w-full px-4 py-2 text-gray-700 placeholder-gray-500 border-gray-300 rounded-r-md focus:outline-none"
                        placeholder="Enter OTP"
                        value={formValue.otp}
                        onChange={handleChange}
                        required
                    />
                </div>
                {errorMessages.otp && <p className="text-red-500 text-left text-[12px]">{errorMessages.otp}</p>}
                {!loading && <button
                    className="w-full bg-[#FF9220] hover:bg-orange-500 text-white font-bold py-2 px-2 rounded-lg h-12"
                    onClick={handleSubmit}
                    type="submit"
                >
                    Submit
                </button>}
                {loading && <div className="grid justify-items-center">
                    <ColorRing
                        visible={loading}
                        height="80"
                        width="80"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper text-center"
                        colors={["#FF9220", "#FF9220", "#FF9220", "#FF9220", "#FF9220"]}
                    />
                </div>}
            </form>
            {!loading && isResentOtp && <p className="text-right text-white text-sm mt-4 cursor-pointer" onClick={resentOTP}>Resend OTP</p>}
        </>
    );
}

export default ValidatePasswordOTP;
