import React, { useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';

const Dropdown = ({ options, selectedOption = 0, onOptionSelect, label, parentClass, id, name, height = "200px", alignment = "text-left" }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOptionClick = (option, id) => {
        onOptionSelect(option, id);
        setIsOpen(false);
    };

    return (
        <div className={`relative ${parentClass}`}>
            <label className={`block text-base text-black text-[12px] ${alignment}`}>{label}</label>
            <button
                className="inline-flex items-center justify-between w-full px-4 py-2 text-[12px] bg-white rounded border border-gray-400"
                onClick={() => setIsOpen(!isOpen)}
            >
                {options[selectedOption - 1]?.label ?? selectedOption?.name ?? "Please Select"}
                <FaAngleDown className="ml-2" />
            </button>
            {isOpen && (
                <div className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg text-[12px]">
                    <ul className="py-2" style={{ height: 'auto', maxHeight: '200px', overflow: "auto" }}>
                        {options.map((option, index) => (
                            <li key={`${option.value}-${index}`} className="px-4 py-2 hover:bg-gray-100">
                                <button
                                    className="w-full text-left focus:outline-none"
                                    onClick={() => handleOptionClick(option, id)}
                                    id={id}
                                >
                                    {option.label ?? option.name}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
