const PRODUCT_MODEL = {
    productId: "",
    productName: "",
    productPrice: 0,
    selected: false
};

export const INITIAL_FORM_VALUE = {
    basicCost: 0,
    gstPercentage: 0,
    gstAmount: 0,
    perTrailerCost: 0,
    quantity: "",
    totalCostOfTrailer: "",
    customerName: "",
    customerAddress: {
        city: "",
        country: "",
        fullAddress: "",
        locality: "",
        pinCode: "",
        state: "",
        streetName: ""
    },
    contactMedium: {
        email: "",
        phoneNumber: ""
    },
    inquiryAttended: "",
    inquiryAttendedDate: "",
    trailerType: PRODUCT_MODEL,
    trailerLength: "",
    trailerWidth: PRODUCT_MODEL,
    trailerHeight: PRODUCT_MODEL,
    trailerAxleType: PRODUCT_MODEL,
    sideWallType: PRODUCT_MODEL,
    trailerDescription: "",
    application: "",
    truckModel: PRODUCT_MODEL,
    mainSpineIBeam: PRODUCT_MODEL,
    axlesMake: PRODUCT_MODEL,
    axlesType: PRODUCT_MODEL,
    suspensionMake: PRODUCT_MODEL,
    suspensionType: PRODUCT_MODEL,
    suspensionCombination: PRODUCT_MODEL,
    tyre: {
        ...PRODUCT_MODEL,
    },
    landingLag: PRODUCT_MODEL,
    radialNylon: "", //TYRE_TYPE
    brakeKit: PRODUCT_MODEL,
    brakeChamber: PRODUCT_MODEL,
    actuators: PRODUCT_MODEL, //NEW_DROPDOWN
    floorSheet: PRODUCT_MODEL,
    paddingPlate: PRODUCT_MODEL, //NEW_DROPDOWN
    coilLashing: PRODUCT_MODEL,
    toolBox: PRODUCT_MODEL, //NEW_DROPDOWN
    crossMember: PRODUCT_MODEL, //NEW_DROPDOWN
    fishPlate: "", //NEW_INPUT
    sideRave: PRODUCT_MODEL, //NEW_DROPDOWN
    taperSupport: PRODUCT_MODEL, //NEW_DROPDOWN
    wheelRim: PRODUCT_MODEL,
    kingpin: PRODUCT_MODEL,
    electrical: "",
    twistLock: PRODUCT_MODEL,
    tieDownHook: PRODUCT_MODEL,
    khutlaPocket: PRODUCT_MODEL,
    khutlaPillarDetails: PRODUCT_MODEL,
    supd: "", //OTHER_ACCESSORIES
    spareWheelCarrierBkt: "",
    reflectiveTape: PRODUCT_MODEL, //NEW_DROPDOWN
    note: "", //DELIVERY_PERIOD
    colourShade: PRODUCT_MODEL, //NEW_DROPDOWN
    fabrication: "", //NEW_INPUT
    lashingPate: PRODUCT_MODEL, //NEW_DROPDOWN
    surfaceTreatment: "", //NEW_INPUT
    tailDoorWindow: PRODUCT_MODEL, //NEW_DROPDOWN
    additionalInformation: "",
    enquiryId: "",
};


export const RADIO_OPTIONS_TYRE_TYPE = [
    { value: "Radial", label: "Radial" },
    { value: "Nylon", label: "Nylon" },
];

export const RADIO_OPTIONS_TRAILER_TYPE = [
    { value: "Trailer", label: "Trailer GST" },
    { value: "Tipper", label: "Tipper GST" },
];

export const SIDE_WALL_TYPES = [
    {
        productId: "Plain sidewall",
        productName: "Plain sidewall",
        productPrice: 0
    }, 
    {
        productId: "Corrugated sidewall",
        productName: "Corrugated sidewall",
        productPrice: 0
    },
    {
        productId: "Not applicable",
        productName: "Not applicable",
        productPrice: 0
    }
];

export const INITIAL_DROPDOWN_OPTIONS = {
    trailerType: [],
    trailerWidth: [],
    trailerHeight: [],
    trailerAxleType: [],
    truckModel: [],
    mainSpineIBeam: [],
    axlesMake: [],
    axlesType: [],
    suspensionMake: [],
    suspensionType: [],
    suspensionCombination: [],
    tyreMake: [],
    landingLeg: [],
    brakeKit: [],
    brakeChamber: [],
    floorSheet: [],
    coilLashing: [],
    wheelRim: [],
    kingpin: [],
    twistLock: [],
    tieDownHook: [],
    khutlaPocket: [],
    khutlaPillarDetails: [],
    actuators: [], //NEW_DROPDOWN
    paddingPlate: [], //NEW_DROPDOWN
    toolBox: [], //NEW_DROPDOWN
    crossMembers: [], //NEW_DROPDOWN
    sideRave: [], //NEW_DROPDOWN
    taperSupport: [], //NEW_DROPDOWN
    reflectiveTape: [], //NEW_DROPDOWN
    colourShade: [], //NEW_DROPDOWN
    lashingPlate: [], //NEW_DROPDOWN
    tailDoorWindow: [],//NEW_DROPDOWN
};

export const REQUIRED_FIELDS = [
    "basicCost",
    "gstPercentage",
    "gstAmount",
    "perTrailerCost",
    "quantity",
    "customerName",
    "customerAddress.fullAddress",
    "contactMedium.phoneNumber",
    "inquiryAttended",
    "inquiryAttendedDate",
    "trailerType.productName",
    "trailerLength",
    "trailerWidth.productName",
    "trailerHeight.productName",
    "trailerAxleType.productName",
    //"sideWallType.productName",
    "truckModel.productName",
    "mainSpineIBeam.productName",
    "axlesMake.productName",
    "axlesType.productName",
    "suspensionType.productName",
    "suspensionMake.productName",
    "suspensionCombination.productName",
    "tyre.productName",
    "landingLag.productName",
    "radialNylon",
    "brakeKit.productName",
    "brakeChamber.productName",
    "actuators.productName",
    "floorSheet.productName",
    "paddingPlate.productName",
    "coilLashing.productName",
    "toolBox.productName",
    "crossMember.productName",
    "fishPlate",
    "sideRave.productName",
    "taperSupport.productName",
    "wheelRim.productName",
    "kingpin.productName",
    "electrical",
    "twistLock.productName",
    "tieDownHook.productName",
    "khutlaPocket.productName",
    "khutlaPillarDetails.productName",
    "supd",
    "spareWheelCarrierBkt",
    "reflectiveTape.productName",
    "note",
    "colourShade.productName",
    "fabrication",
    "lashingPate.productName",
    "surfaceTreatment",
    "tailDoorWindow.productName",
    "additionalInformation"
];

export const ERROR_MESSAGE_VALUES = {
    "basicCost": "",
    "gstPercentage": "",
    "gstAmount": "",
    "perTrailerCost": "",
    "quantity": "",
    "customerName": "",
    "customerAddress.fullAddress": "",
    "contactMedium.phoneNumber": "",
    "inquiryAttended": "",
    "inquiryAttendedDate": "",
    "trailerType.productName": "",
    "trailerLength": "",
    "trailerWidth.productName": "",
    "trailerHeight.productName": "",
    "trailerAxleType.productName": "",
    //"sideWallType.productName": "",
    "truckModel.productName": "",
    "mainSpineIBeam.productName": "",
    "axlesMake.productName": "",
    "axlesType.productName": "",
    "suspensionMake.productName": "",
    "suspensionType.productName": "",
    "suspensionCombination.productName": "",
    "tyre.productName": "",
    "landingLag.productName": "",
    "radialNylon": "",
    "brakeKit.productName": "",
    "brakeChamber.productName": "",
    "actuators.productName": "",
    "floorSheet.productName": "",
    "paddingPlate.productName": "",
    "coilLashing.productName": "",
    "toolBox.productName": "",
    "crossMember.productName": "",
    "fishPlate": "",
    "sideRave.productName": "",
    "taperSupport.productName": "",
    "wheelRim.productName": "",
    "kingpin.productName": "",
    "electrical": "",
    "twistLock.productName": "",
    "tieDownHook.productName": "",
    "khutlaPocket.productName": "",
    "khutlaPillarDetails.productName": "",
    "supd": "",
    "spareWheelCarrierBkt": "",
    "reflectiveTape.productName": "",
    "note": "",
    "colourShade.productName": "",
    "fabrication": "",
    "lashingPate.productName": "",
    "surfaceTreatment": "",
    "tailDoorWindow.productName": "",
    "additionalInformation": ""
};