const Footer = () => {
    const dateYear = new Date().getFullYear();
    return (
        <div className="flex bg-slate-200">
            <span className="text-[#8B8D94] text-[14px] p-4">Copyright © {dateYear} Autocraft Trailers</span>
        </div>
    );
};

export default Footer;
