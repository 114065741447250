export const WORK_ORDER_LIST_HEADER = [
    {
        Header: "Serial No",
        accessor: "serialNumber",
        width: 1
    },
    {
        Header: "Date",
        accessor: "inquiryAttendedDate",
        width: 5
    },
    {
        Header: "Work Order No",
        accessor: "orderId",
        width: 10
    },
    {
        Header: "Chassis No",
        accessor: "chassisNumber",
        width: 25
    },
    {
        Header: "Inquire",
        accessor: "inquiryAttended",
        width: 25
    },
    {
        Header: "Customer Name",
        accessor: "customerName",
        width: 25
    },
    {
        Header: "Trailer Type",
        accessor: "trailerType.productName",
        width: 25
    },
    {
        Header: "Quantity",
        accessor: "quantity",
        width: 5
    },
    {
        Header: "Status",
        accessor: "status",
        width: 5
    }
];


export const VIEW_WORK_ORDER_LIST_HEADER = [
    {
        Header: "Serial No",
        accessor: "serialNumber",
        width: 1
    },
    {
        Header: "Date",
        accessor: "inquiryAttendedDate",
        width: 5
    },
    {
        Header: "Work Order No",
        accessor: "orderId",
        width: 10
    },
    {
        Header: "Chassis No",
        accessor: "chassisNumber",
        width: 25
    },
    {
        Header: "Inquire",
        accessor: "inquiryAttended",
        width: 25
    },
    {
        Header: "Customer Name",
        accessor: "customerName",
        width: 25
    },
    {
        Header: "Trailer Type",
        accessor: "trailerType.productName",
        width: 25
    },
    {
        Header: "Status",
        accessor: "status",
        width: 5
    }
];
