import PropTypes from "prop-types";

const Checkbox = ({ label, checked, onChange, name }) => {
    return (
        <label className="inline-flex items-center">
            <input
                type="checkbox"
                className="form-checkbox h-4 w-4 text-gray-600"
                checked={checked}
                onChange={onChange}
                name={name}
            />
            <span className="ml-2 text-[14px]">{label}</span>
        </label>
    );
};

Checkbox.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.any,
    onChange: PropTypes.func,
    name: PropTypes.string
};

export default Checkbox;
