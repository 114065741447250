//export const API_HOST_URI = "http://autocraft-backend-env-1.eba-za3ffamy.ap-south-1.elasticbeanstalk.com"; //OLD

export const API_HOST_URI =  "http://lat-backend-env.eba-hmhdsidp.ap-south-1.elasticbeanstalk.com"; //TESTING
//export const API_HOST_URI = "http://autocraft-backend-env-1.eba-uvutrned.ap-south-1.elasticbeanstalk.com"; //PRODUCTION

export const orderApi = {
    get: `${API_HOST_URI}/query/order/`,
    post: `${API_HOST_URI}/order/`,
    delete: `${API_HOST_URI}/order/`,
    patch: `${API_HOST_URI}/order/`,
    //getParentsOrder: `${API_HOST_URI}/query/order/getParentOrders`,
    generateChassisWorkOrder: `${API_HOST_URI}/order/generateChassisWorkOrder/`,
    filter: `${API_HOST_URI}/query/order/filter`,
    getParentOrders: `${API_HOST_URI}/query/order/filter?filterAnd=parentOrder%7Cisnull%7C&limit=100000&offset=0&order=DESC&orderBy=lastUpdated`,
    getParentOrdersByUsername: (username) => {
        return `${API_HOST_URI}/query/order/filter?filterAnd=parentOrder%7Cisnull%26createdBy%7Ceq%7C${username.toLowerCase()}&limit=100000&offset=0&order=DESC&orderBy=lastUpdated`
    },
    getSuborders: (parentOrderId) => {
        return `${API_HOST_URI}/query/order/filter?filterAnd=parentOrder%7Ceq%7C${parentOrderId}&limit=1000&offset=0&order=DESC&orderBy=lastUpdated`;
    },
    getSubordersByUsername: (parentOrderId, username) => {
        return `${API_HOST_URI}/query/order/filter?filterAnd=parentOrder%7Ceq%7C${parentOrderId}%26createdBy%7Ceq%7C${username.toLowerCase()}&limit=1000&offset=0&order=DESC&orderBy=lastUpdated`;
    }
}

export const categoryApi = {
    get: `${API_HOST_URI}/query/category/`,
    delete: `${API_HOST_URI}/category/`,
    post: `${API_HOST_URI}/category/`,
    patch: `${API_HOST_URI}/category/`,
    filter: `${API_HOST_URI}/query/category/filter?limit=1000&offset=0&order=DESC&orderBy=createdOn`,
    filterCategory: `${API_HOST_URI}/query/category/filter?limit=1000&offset=0&fields=name&order=DESC&orderBy=lastUpdated` //only name field
}

export const userApi = {
    get: `${API_HOST_URI}/query/user/`,
    post: `${API_HOST_URI}/user/`,
    delete: `${API_HOST_URI}/user/`,
    patch: `${API_HOST_URI}/user/`,
    offline: `${API_HOST_URI}/user/offline/`,
    filter: `${API_HOST_URI}/query/user/filter?limit=2000&offset=0&order=DESC&orderBy=createdOn`,
}

export const userRoleApi = {
    get: `${API_HOST_URI}/query/role/`,
    post: `${API_HOST_URI}/role/`,
    delete: `${API_HOST_URI}/role/`,
    patch: `${API_HOST_URI}/role/`,
    filter: `${API_HOST_URI}/query/role/filter?limit=2000&offset=0&order=DESC&orderBy=createdOn`,
}

export const loginServiceApi = {
    post: `${API_HOST_URI}/user/login/`,
    resetPassword: `${API_HOST_URI}/user/resetPassword/`,
    resetPasswordSendOTP: `${API_HOST_URI}/user/resetPasswordSendOTP/`,
    resetPasswordValidateOTP: `${API_HOST_URI}/user/resetPasswordValidateOTP/`
}

export const offlineServiceApi = {
    post: `${API_HOST_URI}/user/offline/`,
}

export const verifyUserServiceApi = {
    post: `${API_HOST_URI}/user/validateUser/`,
}

export const getOrderDataApi = {
    get: `${API_HOST_URI}/order/getOrderForm`
}

export const productApi = {
    get: `${API_HOST_URI}/query/product/`,
    delete: `${API_HOST_URI}/product/`,
    post: `${API_HOST_URI}/product/`,
    filterByTrailerType: `${API_HOST_URI}/query/product/filter?filterAnd=categoryId%7Ceq%7C01&order=DESC&orderBy=lastUpdated`,
    filter: `${API_HOST_URI}/query/product/filter?limit=100000&offset=0&order=DESC&orderBy=createdOn`,
    patch: `${API_HOST_URI}/product/`,
}

export const enquireApi = {
    get: `${API_HOST_URI}/query/enquiry/`,
    post: `${API_HOST_URI}/enquiry/`,
    patch: `${API_HOST_URI}/enquiry/`,
    delete: `${API_HOST_URI}/enquiry/`,
    filter: `${API_HOST_URI}/query/enquiry/filter?limit=100000&offset=0&order=DESC&orderBy=createdOn`,
    getEnquiryByUsername: (username) => {
        return `${API_HOST_URI}/query/enquiry/filter?filterAnd=createdBy%7Ceq%7C` + username.toLowerCase() + `&limit=100000&offset=0&order=DESC&orderBy=createdOn`
    }
}

export const enquireQuatationApi = {
    get: `${API_HOST_URI}/query/quotation/`,
    post: `${API_HOST_URI}/quotation/`,
    patch: `${API_HOST_URI}/quotation/`,
    delete: `${API_HOST_URI}/quotation/`,
}

export const organizationApi = {
    get: `${API_HOST_URI}/query/organization/`,
    post: `${API_HOST_URI}/organization/`,
    patch: `${API_HOST_URI}/organization/`,
    delete: `${API_HOST_URI}/organization/`,
}

export const quotationTemplateApi = {
    get: `${API_HOST_URI}/query/quotationTemplate`,
    post: `${API_HOST_URI}/quotationTemplate/`,
    patch: `${API_HOST_URI}/quotationTemplate/`,
    delete: `${API_HOST_URI}/quotationTemplate/`,
};

export const bomApi = {
    get: `${API_HOST_URI}/bom/getOrderForm/`,
}

export const dashboardApi = {
    get: ` ${API_HOST_URI}/admin/dashboard/dailyOrderCount`
}