import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";

import AuthProvider from './context/AuthProvider'
import { MaterialTailwindProvider } from './context/MaterialTailwindProvider.jsx';

import App from './App.jsx'
import "../public/css/main.css";
import DataProvider from './context/DataProvider.jsx';

function Main() {
  return (
    // <React.StrictMode>
      <BrowserRouter>
        <ThemeProvider>
          <MaterialTailwindProvider>
            <AuthProvider>
              <DataProvider>
                <App />
              </DataProvider>
            </AuthProvider>
          </MaterialTailwindProvider>
        </ThemeProvider>
      </BrowserRouter>
    // </React.StrictMode>
  );
}

ReactDOM.createRoot(document.getElementById('root')).render(<Main />);
