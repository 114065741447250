import { useState } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { BsCalendarWeek } from "react-icons/bs";

import "react-datepicker/dist/react-datepicker.css";
import "./DateRangePicker.css"; // Create a CSS file for styling

const DateRangePicker = ({ callBackFunction }) => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    return (
        <>
            <div className="flex items-center">
                <DatePicker
                    className="w-full px-4 py-1.5 pr-10 outline-0 rounded border border-gray-400 text-[12px] text-[#8B8D94] placeholder:italic"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        setDateRange(update);
                        callBackFunction(update)
                    }}
                    isClearable={true}
                    placeholderText="Select dates"
                />
                <div className="flex items-center p-2 rounded-r">
                    <BsCalendarWeek className="date-range-picker-icon  text-gray-600" />
                </div>
            </div>

        </>
    );
};

DateRangePicker.propTypes = {
    callBackFunction: PropTypes.func,
};

export default DateRangePicker;
