import React, { useEffect, useState } from 'react';
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { Blocks } from 'react-loader-spinner';
import { TrashIcon, PencilIcon, EyeIcon  } from "@heroicons/react/24/solid";

import Button from "@/components/Button";
import PaginationTable from "@/components/PaginationTable";
import DeleteConfirmationModal from '@/components/DeleteConfirmationModal';
import ContentHeader from "../ContentHeader";

import { APIService } from '@/services';
import { quotationTemplateApi } from '@/utils/API';
import { HandleExport } from '@/utils/HandleExport';
import { icon } from "@/utils/AppConstants";
import columns from './QuotationTemplateListColumn';

const headerButton = {
    showAdd: true,
    showExportBtn: true
};

const QuotationTemplateList = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [templateList, setTemplateList] = useState(null);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [templateListChanged, setTemplateListChanged] = useState(false);

    const getData = async () => {
        setIsLoading(true);
        const response = await APIService.get(quotationTemplateApi.get);
        setTemplateList(response?.quotationTemplateList || []);
        setIsLoading(false);
    };

    useEffect(() => {
        getData();
    }, [templateListChanged]);

    const toggleDeletePopup = () => {
        setIsOpenDeleteModal(!isOpenDeleteModal);
    };

    const handleDelete = async (id) => {
        setIsLoading(true);
        const deleteUrl = `${quotationTemplateApi.delete}${id}`;
        const response = await APIService.delete(deleteUrl);
        setIsOpenDeleteModal(!isOpenDeleteModal);
        setTemplateListChanged(!templateListChanged);
        setIsLoading(false);
    };

    const handleActionClick = async (action, rowData) => {
        if (action === 'delete') {
            const id = rowData?.id;
            setIsOpenDeleteModal(!isOpenDeleteModal);
            setSelectedId(id);
        } else if (action === 'edit') {
        } else if (action === "view") {
        }
    };

    const columnsArray = [
        ...columns,
        {
            Header: 'Action',
            accessor: 'status',
            width: 5,
            Cell: ({ value, row }) => (
                <div className="text-[25] flex justify-center">
                    <PencilIcon {...icon} />
                    <EyeIcon {...icon} />
                    <TrashIcon {...icon} onClick={() => handleActionClick('delete', row.original)} />
                </div>
            ),
        },
    ];

    return (
        <>
            <ContentHeader
                title="Quotation Templates"
                description="Quotation Templates List"
                showHeader={true}
                showDatePicker={false}
                showSearchInput={false}
                showButton={false}
                btnToShow={headerButton}
            />
            <div className="m-4 flex flex-col rounded h-auto bg-white">
                {isOpenDeleteModal && selectedId && (
                    <DeleteConfirmationModal
                        handleDelete={handleDelete}
                        isOpen={isOpenDeleteModal}
                        togglePopup={toggleDeletePopup}
                        id={selectedId}
                    />
                )}

                {/* <span className="text-xl font-small pt-3.5 pl-3.5">Quotation Templates List</span> */}

                <div className="flex justify-end mr-5 mt-4 mb-4">
                    <div className='mr-2'>
                        <Button text="Add Template" icon="FaPlus" textColor="white" bgColor="[#39ACE4]" onClick={() => navigate('/dashboard/AdminDepartment/QuotationTemplates/Add') } />
                    </div>
                    { !isEmpty(templateList) &&
                        <div>
                            <Button text="Export to Excel" icon="FaDownload" textColor="white" bgColor="[#39ACE4]" onClick={() => { HandleExport(columnsArray, templateList, "QuotationTemplateList") }} />
                        </div>
                    }
                </div>
                {isLoading ? (
                    <div className='flex items-center justify-center'>
                        <Blocks
                            height="80"
                            width="80"
                            color="#4fa94d"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            visible={true}
                        />
                    </div>
                ) : (
                    templateList && <PaginationTable columnsData={columnsArray} rowData={templateList} />
                )}
            </div>
        </>
    );
}

export default QuotationTemplateList;
