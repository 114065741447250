import PropTypes from "prop-types";
import { Scrollbars } from 'react-custom-scrollbars';

function CustomScrollbarWrapper({ children, style, customClass }) {
    return (
        <Scrollbars
            style={style}
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            renderThumbVertical={({ style }) => (
                <div className={`custom-scrollbar-thumb ${customClass}`} style={style} />
            )}
        >
            {children}
        </Scrollbars>
    );
}

CustomScrollbarWrapper.propTypes = {
    children: PropTypes.any,
    style: PropTypes.object,
    customClass: PropTypes.string,
};

export default CustomScrollbarWrapper;
