import { useState } from "react";
import "./WorkOrderContainer.css";

import ContentHeader from "../../ContentHeader";
import WorkOrderList from "./WorkOrderList";

const headerButton = {
  showAdd: true,
  showExportBtn: true
};

const icon = {
  className: "w-5 h-5 text-[#39ACE4] m-2 cursor-pointer"
};

const WorkOrderContainer = () => {

  const [activeTab, setActiveTab] = useState(0);

  const onTabClick = (index) => {
    setActiveTab(index);
  };

  const getActiveTabName = () => {
    if (activeTab === 0) return "Initiate";
    if (activeTab === 1) return "Approved";
    if (activeTab === 2) return "Hold";
    return "";
  }

  return (
    <>
      <ContentHeader
        title="Admin Approval"
        description={`Admin Approval / ${getActiveTabName()}`}
        showHeader={true}
        showDatePicker={false}
        showSearchInput={false}
        showButton={false}
        btnToShow={headerButton}
      />
      <div className="m-4 flex flex-col rounded-md h-auto">
        <div className="m-2 bg-white rounded-md">
          <div className="flex">
            <ul className="w-full inline-flex">
              <li className={`w-1/3 text-[14px] cursor-pointer p-4 ${activeTab === 0 ? "text-[#1A91D1] border-b-2 border-[#1A91D1]" : "text-[#6E6E6E]"}`} onClick={() => onTabClick(0)}>Initiate</li>
              <li className={`w-1/3 text-[14px] cursor-pointer p-4 ${activeTab === 1 ? "text-[#1A91D1] border-b-2 border-[#1A91D1]" : "text-[#6E6E6E]"}`} onClick={() => onTabClick(1)}>Approved</li>
              <li className={`w-1/3 text-[14px] cursor-pointer p-4 ${activeTab === 2 ? "text-[#1A91D1] border-b-2 border-[#1A91D1]" : "text-[#6E6E6E]"}`} onClick={() => onTabClick(2)}>Hold</li>
            </ul>
          </div>

          {
            activeTab === 0 &&
            <div className="tabcontent">
              {/* <h1 className="text-left ml-4 my-3 text-[18px] uppercase">Initiate</h1> */}
              <WorkOrderList tabIndex={activeTab} showAction={true} />
            </div>
          }

          {
            activeTab === 1 &&
            <div className="tabcontent">
              {/* <h1 className="text-left ml-4 my-3 text-[18px] uppercase">Approved</h1> */}
              <WorkOrderList tabIndex={activeTab} showAction={false} />
            </div>
          }

          {
            activeTab === 2 &&
            <div className="tabcontent">
              {/* <h1 className="text-left ml-4 my-3 text-[18px] uppercase">Hold</h1> */}
              <WorkOrderList tabIndex={activeTab} showAction={false} />
            </div>
          }
        </div>
      </div>
    </>
  );
}

export default WorkOrderContainer;
