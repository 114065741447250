import React from 'react';

const TextAreaField = ({ name, label, placeholder, value, onChange, disabled = false, required = false, alignment = "text-left", onBlur = () => { } }) => {
    return (
        <div className="">
            {label && <label className={`block text-black text-[12px] ${alignment}`}>{label} {required && <span className="text-red-500 text-[12px] font-extrabold">*</span>}</label>}
            <textarea
                name={name}
                className={`block w-full outline-0  px-4 py-2 text-[12px] bg-white rounded border border-gray-400 ${disabled ? 'opacity-50' : ''}`}
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChange(e)}
                onBlur={(e) => onBlur(e)}
                disabled={disabled}
                rows={4} // You can adjust the number of rows as needed
            />
        </div>
    );
};

export default TextAreaField;
