import React from 'react';

const ErrorMessage = ({ error = "" }) => {
  return (
    <p className="text-red-500 text-[12px] mt-1 text-left">
      {error || `This field is required.`}
    </p>
  );
}

export default ErrorMessage;
