export const CATEGORY_HEADER = [
    {
        Header: "Serial No.",
        accessor: "serialNumber",
        width: 5
    },
    {
        Header: "Name",
        accessor: "name",
        width: 25
    },
    {
        Header: "Description",
        accessor: "description",
        width: 25
    },
];


export const PRDODUCT_HEADER = [
    {
        Header: "Serial No",
        accessor: "serialNumber",
        width: 5
    },
    {
        Header: "Name",
        accessor: "name",
        width: 25
    },
    {
        Header: "Description",
        accessor: "description",
        width: 25
    },
    {
        Header: "Category",
        accessor: "categoryName",
        width: 25
    },
    {
        Header: "Original Price",
        accessor: "originalPrice",
        width: 25
    },
    {
        Header: "Stock Price",
        accessor: "stock",
        width: 25
    },
];
