import moment from "moment";
import "./PrintWorkOrder.css";
import { forwardRef } from "react";

const PrintWorkOrder = forwardRef(({ order, enquiry }, ref) => {

    const printDate = () => {
        return moment().format("ddd, MMMM Do YYYY, h:mm A");
    };

    const formatDate = (selectedDate) => {
        if (selectedDate === null || selectedDate === "") return null;
        return moment(selectedDate).format("ddd, MMMM Do YYYY, h:mm A");
    };

    return (
        <div className="mx-4 rounded h-auto bg-white" ref={ref}>
            <style>
                {`table{border-collapse:collapse}.mx-4{margin-left:1rem;margin-right:1rem}.my-1{margin-top:0px;margin-bottom:.5rem}.my-2{margin-top:.5rem;margin-bottom:.5rem}.mb-4{margin-bottom:1rem}.mt-4{margin-top:1rem}.p-2{padding:.5rem}.grid{display:grid}.gap-4{gap:1rem}.col-start-3{grid-column-start:3}.grid-cols-1{grid-template-columns:repeat(1,minmax(0,1fr))}.inline-grid{display:inline-grid}.grid-cols-3{grid-template-columns:repeat(3,minmax(0,1fr))}.rounded{border-radius:.25rem}.text-slate-700{color:rgb(51 65 85)}.bg-white{background-color:#fff}.self-center{align-self:center}.justify-self-center{justify-self:center}.text-[20px]{font-size:20px}.text-[14px]{font-size:14px}.font-bold{font-weight:700}.w-full{width:100%}.h-auto{height:auto}.text-left{text-align:left}.text-right{text-align:right}.border{border:1px solid rgb(148 163 184)}img{max-width:185px}td{overflow-wrap:anywhere}.w-250{width:250px}td{border:1px solid #e5e7eb;padding:5px}`}
            </style>
            <div className="grid grid-cols-3 gap-4">
                <img src="http://autocraft.flexibiz.in/images/autocraft_trailers.png" />
                <div className="self-center justify-self-center text-[20px] font-bold">Work Order</div>
            </div>
            <div className="mx-4 my-2">
                    <table className="text-[14px] text-slate-700 w-full border">
                        <tbody>
                            <tr>
                                <td className="w-250">Work Order No</td>
                                <td className="text-left">{order?.orderId}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Work Order Date</td>
                                <td className="text-left">{formatDate(order?.lastUpdated)}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Delivery Period</td>
                                <td className="text-left">{order?.customerAddress?.country}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Transport Name</td>
                                <td className="text-left">{enquiry.transporterName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Address</td>
                                <td className="text-left">{order?.customerAddress?.fullAddress}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Enquiry Attended by</td>
                                <td className="text-left">{order?.inquiryAttended}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="mt-4 text-[14px] text-slate-700 w-full border">
                        <tbody>
                            <tr>
                                <td className="w-250">Trailer Type</td>
                                <td className="text-left">{order?.trailerType?.productName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Trailer Dimensions</td>
                                <td className="text-left">
                                    <p className="my-1">Length: {order?.length}</p>
                                    <p className="my-1">Width: {order?.width}</p>
                                    <p className="my-1">Height: {order?.height}</p>
                                    <p className="my-1">Axle Type: {order?.axleType}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="w-250">Trailer Description</td>
                                <td className="text-left">{order?.trailerDescription}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Application</td>
                                <td className="text-left">{order?.application}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Main Spine I-Beam</td>
                                <td className="text-left">{order?.mainSpineIBeam?.productName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Prime Mover Model</td>
                                <td className="text-left">{order?.truckModel?.productName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Axles</td>
                                <td className="text-left">
                                    <p className="my-1">Make: {order?.axlesMake?.make[0]?.productName}</p>
                                    <p className="my-1">Type: {order?.axlesMake?.type[0]?.productName}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="w-250">Suspension</td>
                                <td className="text-left">
                                    <p className="my-1">Combination: {order?.suspension?.combination[0]?.productName}</p>
                                    <p className="my-1">Make: {order?.suspension?.make[0]?.productName}</p>
                                    <p className="my-1">Type: {order?.suspension?.type[0]?.productName}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="mt-4 text-[14px] text-slate-700 w-full border">
                        <tbody>
                            <tr>
                                <td className="w-250">Tyre</td>
                                <td className="text-left">
                                    <p className="my-1">Make: {order?.tyre?.make[0]?.productName}</p>
                                    <p className="my-1">Type: {order?.tyre?.nylon ? "Nylon" : "Radial"}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="w-250">Breaking System</td>
                                <td className="text-left">
                                    <p className="my-1">Brake Kit Make: {order?.brakeKit?.productName}</p>
                                    <p className="my-1">Brake Chambers: {order?.brakeChamber?.productName}</p>
                                    <p className="my-1">Actuators: {order?.actuators?.productName}</p>
                                </td>
                            </tr>
                            <tr>
                                <td className="w-250">Landing Leg</td>
                                <td className="text-left">{order?.landingLag?.productName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Coil Mounting</td>
                                <td className="text-left">{order?.coilLashing?.productName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Platform Floor</td>
                                <td className="text-left">{order?.floorSheet?.productName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Tool Box</td>
                                <td className="text-left">{order?.toolBox?.productName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Padding Plate</td>
                                <td className="text-left">{order?.paddingPlate?.productName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Cross Members</td>
                                <td className="text-left">{order?.crossMember?.productName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Fish Plate</td>
                                <td className="text-left">{order?.fishPlate}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Side Rave</td>
                                <td className="text-left">{order?.sideRave?.productName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Taper Support</td>
                                <td className="text-left">{order?.taperSupport?.productName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Wheel Rim</td>
                                <td className="text-left">{order?.wheelRim?.productName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Kingpin</td>
                                <td className="text-left">{order?.kingpin?.productName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Twist Lock</td>
                                <td className="text-left">{order?.twistLock?.productName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Electrical</td>
                                <td className="text-left">{order?.electrical}</td>
                            </tr>
                            <tr>
                                <td className="w-250">J' Hook</td>
                                <td className="text-left">{order?.tieDownHook?.productName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Spare Wheel Bracket</td>
                                <td className="text-left">{order?.spareWheelCarrierBkt}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Khutla Pocket (Qty)</td>
                                <td className="text-left">{order?.khutlaPocket?.productName}<div className="watermark"></div></td>
                            </tr>
                            
                            <tr>
                                <td className="w-250">Tail Door Window</td>
                                <td className="text-left">{order?.tailDoorWindow?.productName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Khutla Pillar Details</td>
                                <td className="text-left">{order?.khutlaPillarDetails?.productName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Lashing Plate</td>
                                <td className="text-left">{order?.lashingPate?.productName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Fabrication Welding</td>
                                <td className="text-left">{order?.fabrication}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="mt-4 text-[14px] text-slate-700 w-full border">
                        <tbody>
                            <tr>
                                <td className="w-250">Reflective Tape</td>
                                <td className="text-left">{order?.reflectiveTape?.productName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Surface Treatment</td>
                                <td className="text-left">{order?.surfaceTreatment}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Colour Shade</td>
                                <td className="text-left">{order?.colourShade?.productName}</td>
                            </tr>
                            <tr>
                                <td className="w-250">Additional Information</td>
                                <td className="text-left p-4">{order?.additionalInformation}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="text-right mt-4">
                        <span className="text-[14px]"><strong>Print on:</strong> {printDate()}</span>
                    </div>
            </div>
        </div>
    );
});

export default PrintWorkOrder;
