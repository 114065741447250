import { Navigate } from 'react-router-dom';

import Container from './Container';
import { useAuth } from '../hooks/useAuth';

const ProtectedRoute = () => {
    const user = useAuth();
    if (!user.token) return <Navigate to='/login' />;
    return <Container />
};

export default ProtectedRoute;
