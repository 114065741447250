import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { get, cloneDeep, set, isEmpty } from "lodash";
import { Blocks } from "react-loader-spinner";
import { toast } from "react-toastify";

import Button from "@/components/Button";
import InputField from "@/components/InputField";
import ContentHeader from "../ContentHeader";

import { useData } from "@/hooks/useData";
import { APIService } from "@/services";
import { organizationApi } from "@/utils/API";
import { scrollToTop } from "@/utils/CustomFunctions";
import { 
    INITIAL_FORM_VALUE,
    REQUIRED_FIELDS,
    ERROR_MESSAGE_VALUES
} from "./constants";
import { APP_URLS } from "@/routes";

const Settings = () => {
    const navigate = useNavigate();
    const { refreshData } = useData();

    const [isLoading, setIsLoading] = useState(false);
    const [formValues, setFormValues] = useState(INITIAL_FORM_VALUE);
    const [isOrgDetailsExist, setIsOrgDetailsExist] = useState(false);
    const [organizationId, setOrganizationId] = useState("")
    const [errorMessages, setErrorMessages] = useState(ERROR_MESSAGE_VALUES);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedState = cloneDeep(formValues);
        set(updatedState, name, value);
        setFormValues(updatedState);

        setErrorMessages((prevErrorMessages) => ({
            ...prevErrorMessages,
            [name]: "",
        }));
    };

    const handleSubmit = async () => {
        setIsLoading(true);

        let newErrorMessages = {};
        REQUIRED_FIELDS.forEach((field) => {
            if (!get(formValues, field)) {
                newErrorMessages[field] = "This field is required.";
            } else {
                newErrorMessages[field] = "";
            }
        });

        setErrorMessages(newErrorMessages);
        if (Object.values(newErrorMessages).some((errorMsg) => errorMsg !== "")) {
            setIsLoading(false);
            scrollToTop();
            toast.error("Please fill mandatory fields");
            return;
        };

        const payload = {
            address: { ...formValues.address || {} },
            bankDetails: { ...formValues.bankDetails || {} },
            contactMedium: { ...formValues.contactMedium || {} },
            gstDetails: { ...formValues.gstDetails || {} },
            gstNum: formValues.gstNum || "",
            hsnOrSACNum: formValues.hsnOrSACNum || "",
            organizationName: formValues.organizationName || "",
            organizationSubtitle: formValues.organizationSubtitle || "",
            panNum: formValues.panNum || "",
            websiteURL: formValues.websiteURL || ""
        };
        console.log('payload', payload)

        let response = {};
        if (isOrgDetailsExist) {
            response = await APIService.patch(`${organizationApi.patch}${organizationId}`, payload);
        } else {
            response = await APIService.post(organizationApi.post, payload);
        }

        toast.success("Details saved successfully");
        refreshData(); // refresh data provider

        navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.SALES_ENQ_LIST}`);
    };

    const getOrganzationData = async () => {
        setIsLoading(true);
        const response = await APIService.get(`${organizationApi.get}`);
        if (response.organizationList && !isEmpty(response.organizationList)) {
            patchForm(response.organizationList[0]);
        }
        setIsLoading(false);
    };

    const patchForm = (organization) => {
        setIsOrgDetailsExist(true);
        setOrganizationId(organization.id);

        const updatedValues = {
            ...organization,
            address: { ...organization?.address || {} },
            bankDetails: { ...organization?.bankDetails || {} },
            contactMedium: { ...organization?.contactMedium || {} },
            gstDetails: { ...organization.gstDetails || {} },
            gstNum: organization.gstNum || "",
            hsnOrSACNum: organization.hsnOrSACNum || "",
            organizationName: organization.organizationName || "",
            organizationSubtitle: organization.organizationSubtitle || "",
            panNum: organization.panNum || "",
            websiteURL: organization.websiteURL || ""
        };

        setFormValues(updatedValues);
    };

    useEffect(() => {
        getOrganzationData();
    }, []);

    return <>
        {isLoading ? (
            <div className="flex items-center justify-center">
                <Blocks
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    visible={true}
                />
            </div>
        ) : (
            <>
                <ContentHeader
                    title="General Settings"
                    description="Settings"
                    showHeader={true}
                    showDatePicker={false}
                    showSearchInput={false}
                    showButton={false}
                />
                <div className="m-4 flex flex-col rounded h-auto bg-white">
                    <span className="text-xl font-small uppercase text-left m-2">Company name and Address</span>
                    <div className="grid grid-cols-2 gap-x-8  p-3.5">
                        <div className="mb-4">
                            <InputField
                                name="organizationName"
                                label="Company Name"
                                placeholder=""
                                value={get(formValues, "organizationName")}
                                onChange={handleInputChange}
                            />
                            {errorMessages.organizationName && <p className="text-red-500 text-[12px] mt-1 text-left">{errorMessages.organizationName}</p>}
                        </div>
                        <div className="mb-4">
                            <InputField
                                name="organizationSubtitle"
                                label="Sub Title"
                                placeholder=""
                                value={get(formValues, "organizationSubtitle")}
                                onChange={handleInputChange}
                            />
                            {errorMessages.organizationSubtitle && <p className="text-red-500 text-[12px] mt-1 text-left">{errorMessages.organizationSubtitle}</p>}
                        </div>
                        <div className="mb-4">
                            <InputField
                                name="address.city"
                                label="City"
                                placeholder=""
                                value={get(formValues, "address.city")}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="mb-4">
                            <InputField
                                name="address.fullAddress"
                                label="Address"
                                placeholder=""
                                value={get(formValues, "address.fullAddress")}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="mb-4">
                            <InputField
                                name="address.locality"
                                label="Locality"
                                placeholder=""
                                value={get(formValues, "address.locality")}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="mb-4">
                            <InputField
                                name="address.streetName"
                                label="Street Name"
                                placeholder=""
                                value={get(formValues, "address.streetName")}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="mb-4">
                            <InputField
                                name="address.state"
                                label="State"
                                placeholder=""
                                value={get(formValues, "address.state")}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="mb-4">
                            <InputField
                                name="address.pinCode"
                                label="Pincode"
                                placeholder=""
                                value={get(formValues, "address.pinCode")}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            <div className="mb-4">
                                <InputField
                                    name="contactMedium.phoneNumber"
                                    label="Mobile No."
                                    placeholder=""
                                    value={get(formValues, "contactMedium.phoneNumber")}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-4">
                                <InputField
                                    name="contactMedium.email"
                                    label="Email Id"
                                    placeholder=""
                                    value={get(formValues, "contactMedium.email")}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="mb-4">
                            <InputField
                                name="websiteURL"
                                label="Website URL"
                                placeholder=""
                                value={get(formValues, "websiteURL")}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="mb-4">
                            <InputField
                                name="gstNum"
                                label="GST No."
                                placeholder=""
                                value={get(formValues, "gstNum")}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="mb-4">
                            <InputField
                                name="panNum"
                                label="PAN No."
                                placeholder=""
                                value={get(formValues, "panNum")}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="mb-4">
                            <InputField
                                name="hsnOrSACNum"
                                label="HSN/SAV No."
                                placeholder=""
                                value={get(formValues, "hsnOrSACNum")}
                                onChange={handleInputChange}
                            />
                        </div>

                    </div>
                    <div className="grid grid-cols-1 gap-x-8 p-3.5">
                        <div className="grid grid-cols-1 gap-6">
                            <span className="text-xl font-small uppercase text-left m-2">GST Details</span>
                            <div className="grid grid-cols-4 gap-4">
                                <div className="mb-4">
                                    <InputField
                                        name="gstDetails.prefix"
                                        label="Prefix"
                                        placeholder=""
                                        value={get(formValues, "gstDetails.prefix")}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="mb-4">
                                    <InputField
                                        name="gstDetails.igst"
                                        label="Trailer GST %"
                                        placeholder=""
                                        value={get(formValues, "gstDetails.igst")}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="mb-4">
                                    <InputField
                                        name="gstDetails.cgst"
                                        label="Tipper GST %"
                                        placeholder=""
                                        value={get(formValues, "gstDetails.cgst")}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="mb-4">
                                    <InputField
                                        name="gstDetails.sgst"
                                        label="SGST %"
                                        placeholder=" "
                                        value={get(formValues, "gstDetails.sgst")}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-x-8 p-3.5">
                        <div className="grid grid-cols-1 gap-6">
                            <span className="text-xl font-small uppercase text-left m-2">Bank Details</span>
                            <div className="grid grid-cols-5 gap-4">
                                <div className="mb-4">
                                    <InputField
                                        name="bankDetails.bankName"
                                        label="Bank Name"
                                        placeholder=""
                                        value={get(formValues, "bankDetails.bankName")}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="mb-4">
                                    <InputField
                                        name="bankDetails.accountNumber"
                                        label="Account Number"
                                        placeholder=""
                                        value={get(formValues, "bankDetails.accountNumber")}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="mb-4">
                                    <InputField
                                        name="bankDetails.isfccode"
                                        label="IFSC Code"
                                        placeholder=""
                                        value={get(formValues, "bankDetails.isfccode")}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="mb-4">
                                    <InputField
                                        name="bankDetails.upiID"
                                        label="UPI ID"
                                        placeholder=" "
                                        value={get(formValues, "bankDetails.upiID")}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="mb-4">
                                    <InputField
                                        name="bankDetails.branchName"
                                        label="Branch Name"
                                        placeholder=" "
                                        value={get(formValues, "bankDetails.branchName")}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pl-3.5 mb-10">
                        <Button text="Save" textColor="white" bgColor="[#39ACE4]" onClick={() => handleSubmit()} />
                    </div>
                </div>
            </>
        )}
    </>
}

export default Settings;
