    
export const QUOTATION_LIST_COLUMN  = [
    {
        Header: 'Serial No',
        accessor: 'serialNo',
        width: 5
    },
    {
        Header: 'Date',
        accessor: 'inquiryAttendedDate',
        width: 10
    },
    {
        Header: 'Quotattion Id',
        accessor: 'quotationId',
        width: 10
    },
    {
        Header: 'Inquire',
        accessor: 'inquiryAttended',
        width: 25
    },
    {
        Header: 'Customer Name',
        accessor: 'customerName',
        width: 25
    },
    {
        Header: 'Trailer Type',
        accessor: 'trailerType.productName',
        width: 25
    },
    {
        Header: 'Quantity',
        accessor: 'quantity',
        width: 5
    },
];
