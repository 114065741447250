import React, { useEffect, useState } from 'react';
import { isEmpty } from "lodash";
import { useNavigate } from 'react-router-dom';
import { Blocks } from "react-loader-spinner";
import { TrashIcon, PencilIcon, EyeIcon, BarsArrowUpIcon } from "@heroicons/react/24/solid";

import Button from "@/components/Button";
import PaginationTable from "@/components/PaginationTable";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal";
import ContentHeader from "../ContentHeader";

import { useAuth } from "@/hooks/useAuth";
import { APIService } from "@/services";
import { orderApi } from "@/utils/API";
import { filterOrders, addSerialNumbersToList } from "@/utils/CustomFunctions";
import { WORK_ORDER_LIST_HEADER } from "./Headers";
import { HandleExport } from "@/utils/HandleExport";
import { icon } from "@/utils/AppConstants";
import { APP_URLS } from "@/routes";

const headerButton = {
    showAdd: true,
    showExportBtn: true
};

const WorkOrderList = () => {
    const navigate = useNavigate();
    const { user: auth } = useAuth();

    const isAdmin = auth.roleList.indexOf("1") !== -1;
    const [workOrderList, setWorkOrderList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [orderPlaceListChanged, setOrderPlaceListChanged] = useState(false);

    const toggleDeletePopup = () => {
        setIsOpenDeleteModal(!isOpenDeleteModal);
    };

    const getData = async () => {
        setIsLoading(true);

        let url = isAdmin ? orderApi.getParentOrders: orderApi.getParentOrdersByUsername(auth.username); // get base order only
        const response = await APIService.get(url);
        const updatedList = await addSerialNumbersToList(response.orderList);
        setWorkOrderList(updatedList);
        setIsLoading(false);
    };

    useEffect(() => {
        getData();
    }, [orderPlaceListChanged]);

    const handleDelete = async (id) => {
        setIsLoading(true);
        const deleteUrl = `${orderApi.delete}${id}`;
        const response = await APIService.delete(deleteUrl);
        setIsOpenDeleteModal(!isOpenDeleteModal);
        setOrderPlaceListChanged(!orderPlaceListChanged);
        setIsLoading(false);
    };

    const handleActionClick = async (action, rowData) => {
        if (action === "delete") {
            const id = rowData?.id;
            setIsOpenDeleteModal(!isOpenDeleteModal);
            setSelectedId(id);
        } else if (action === "edit") {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.EDIT_WO}?quotationId=${rowData.quotationId}&workOrderId=${rowData.id}&orderType=0`);
        } else if (action === "viewsuborder") {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.SALE_VIEW_WO_LIST}?workOrderId=${rowData.id}&quotationId=${rowData.quotationId}`);
        } else if (action === "view") {
            navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.VIEW_WO}?quotationId=${rowData.quotationId}&workOrderId=${rowData.id}&orderType=0&mode=view`);
        }
    };

    // function to filter orders based on search input
    function filterDataFunction(searchInput) {
        const { value } = searchInput?.target
        const filteredOrders = filterOrders(value, workOrderList);

        if (filteredOrders.length === 0) {
            setOrderPlaceListChanged(!orderPlaceListChanged); 
        } else {
            setWorkOrderList(filteredOrders)
        }
    }

    const columnsArray = [
        ...WORK_ORDER_LIST_HEADER,
        {
            Header: "Action",
            accessor: "action",
            width: 5,
            Cell: ({ value, row }) => {
                const { status } = row.original;
                return (
                    <div className="text-[25] flex justify-center text-[#39ACE4]">
                        <EyeIcon title="View Order Details" {...icon} onClick={() => handleActionClick("view", row.original) } />
                        { status === "Approved" && <BarsArrowUpIcon {...icon} title="View Sub Orders" onClick={() => handleActionClick("viewsuborder", row.original)} /> }
                        { status === "Initial" && <PencilIcon {...icon} title="Edit Order" onClick={() => handleActionClick("edit", row.original)} /> }
                        {/* { isAdmin && status && <TrashIcon {...icon} title="Delete Order" onClick={() => handleActionClick("delete", row.original)} /> } */}
                    </div>
                )
            },
        },
    ];

    return (
        <>
            <ContentHeader
                title="Work Order"
                description="Sales Department / Work Order"
                showHeader={true}
                showDatePicker={false}
                showSearchInput={false}
                showButton={false}
                btnToShow={headerButton}
                filterDataFunction={filterDataFunction}
            />
            <div className="m-4 flex flex-col rounded h-auto bg-white">
                {isOpenDeleteModal && selectedId && (
                    <DeleteConfirmationModal
                        handleDelete={handleDelete}
                        isOpen={isOpenDeleteModal}
                        togglePopup={toggleDeletePopup}
                        id={selectedId}
                    />
                )}

                {/* <span className="text-xl font-small pt-3.5 pl-3.5">Work Order List</span> */}

                <div className="flex justify-end mr-5 mt-4 mb-4">
                    { !isEmpty(workOrderList) &&
                        <div>
                            <Button text="Export to Excel" icon="FaDownload" textColor="white" bgColor="[#39ACE4]" onClick={() => { HandleExport(columnsArray, workOrderList, "WorkOrderList") }} />
                        </div>
                    }
                </div>

                {isLoading ? (
                    <div className="flex items-center justify-center">
                        <Blocks
                            height="80"
                            width="80"
                            color="#4fa94d"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            visible={true}
                        />
                    </div>
                ) : (
                    workOrderList && <PaginationTable columnsData={columnsArray} rowData={workOrderList} />
                )}
            </div>
        </>
    )
}

export default WorkOrderList;
