import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Blocks } from "react-loader-spinner";
import { isEmpty, omit } from "lodash";

import { APIService } from "@/services";
import { enquireQuatationApi, enquireApi, orderApi } from "@/utils/API";

import Button from "@/components/Button";
import ContentHeader from "../ContentHeader";
import PrintWorkOrder from "./PrintWorkOrder";

const headerButton = { showBackBtn: true };

const ViewWorkOrder = () => {
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();
    const componentRef = useRef();

    const quotationId = queryParams.get("quotationId");
    const orderId = queryParams.get("workOrderId");


    const [isLoading, setIsLoading] = useState(false);
    const [quotation, setQuotation] = useState({});
    const [enquiry, setEnquiry] = useState({});
    const [order, setOrder] = useState({});

    const takePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handlePrint = () => {
        takePrint();
    };

    const getDetails = async () => {
        setIsLoading(true);
        const orderResponse = await APIService.get(`${orderApi.get}${orderId}`);
        if (orderResponse?.order) {
            setOrder(orderResponse.order);
        };

        const quoResponse = await APIService.get(`${enquireQuatationApi.get}${quotationId}`);
        if (quoResponse?.quotation) {
            setQuotation(quoResponse.quotation);

            const enqResponse = await APIService.get(`${enquireApi.get}${quoResponse.quotation.enquiryId}`);
            setEnquiry(omit(enqResponse?.enquiry,["quotationList"]) || {});
        };
        setIsLoading(false);
    };

    useEffect(() => {
        getDetails();
    }, []);

    return (
        <>
            {isLoading ? (
                <div className='flex items-center justify-center'>
                <Blocks
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    visible={true}
                />
            </div>
            ) : (
                <>
                    <ContentHeader 
                            title="Work Order"
                            description="View"
                            showHeader={true}
                            showDatePicker={false}
                            showSearchInput={false}
                            showButton={true}
                            btnToShow={headerButton}
                    />
                    {(!isEmpty(order) && !isEmpty(enquiry)) &&
                        <>
                            <PrintWorkOrder
                                ref={componentRef}
                                enquiry={enquiry}
                                order={order}
                            />
                            <div className="flex justify-center">
                                <div className="m-2">
                                    <Button text="Print Order" icon="" textColor="white" bgColor="[#1A91D1]" onClick={() => handlePrint()} />
                                </div>
                                <div className="m-2">
                                    <Button text="Back" textColor="white" bgColor="[#1A91D1]" onClick={() => navigate(-1)} />
                                </div>
                            </div>
                        </>
                    }
                </>
            )}
        </>
    );
};

export default ViewWorkOrder;
