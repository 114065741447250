import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Blocks } from "react-loader-spinner";
import { TrashIcon, PencilIcon, EyeIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";

import PaginationTable from "@/components/PaginationTable";
import Button from "@/components/Button";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal";
import ContentHeader from "../ContentHeader";

import { APIService } from "@/services";
import { enquireApi, enquireQuatationApi } from "@/utils/API";
import { HandleExport } from "@/utils/HandleExport";
import { icon } from "@/utils/AppConstants";
import { QUOTATION_LIST_COLUMN } from "./EnquireQuotationListColumn";
import { APP_URLS } from "@/routes";

const headerButton = {
    showAdd: true,
    showExportBtn: true
};

const EnquireQuotationList = () => {
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();

    const enquiryId = queryParams.get("enquiryId");

    const [isLoading, setIsLoading] = useState(false);
    const [enquireQuotationList, setEnquireQuotationList] = useState(null);
    const [quotationListChanged, setQuotationListChanged] = useState(false);
    const [selectedId, setSelectedId] = useState("");
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

    const toggleDeletePopup = () => {
        setIsOpenDeleteModal(!isOpenDeleteModal);
    };

    const getData = async () => {
        setIsLoading(true);
        const response = await APIService.get(`${enquireApi.get}${enquiryId}`);
        setEnquireQuotationList(response?.enquiry?.quotationList || []);
        setIsLoading(false);
    };

    useEffect(() => {
        getData();
    }, [quotationListChanged]);

    const handleDelete = async (id) => {
        setIsLoading(true);
        const deleteUrl = `${enquireQuatationApi.delete}${id}`;
        await APIService.delete(deleteUrl);
        setIsOpenDeleteModal(!isOpenDeleteModal);
        setQuotationListChanged(!quotationListChanged);
        setIsLoading(false);
        toast.success("Quotation delete successfully");
    };

    const handleActionClick = async (action, rowData) => {
        if (action === "delete") {
            const id = rowData?.id;
            setIsOpenDeleteModal(!isOpenDeleteModal);
            setSelectedId(id);
        } else if (action === "edit") {
            const id = rowData?.id;
            const queryParams = `?quotationId=${id}&enquireId=${rowData?.enquiryId}`;
            const fullURL = `${APP_URLS.APP_ROOT}/${APP_URLS.SALES_ENQ_QUO}` + queryParams;
            navigate(fullURL);
        } else if (action === "view") {
            const id = rowData?.id;
            const queryParams = `?enquireId=${rowData?.enquiryId}&quotationId=${id}`;
            const fullURL = `${APP_URLS.APP_ROOT}/${APP_URLS.VIEW_QUO}` + queryParams;
            navigate(fullURL);
        }
    };

    const columnsArray = [
        ...QUOTATION_LIST_COLUMN,
        {
            Header: "Action",
            accessor: "status",
            width: 5,
            Cell: ({ value, row }) => (
                <div className="flex justify-center">
                    <PencilIcon {...icon} onClick={() => handleActionClick("edit", row.original)} title="Edit Quotation" />
                    <EyeIcon {...icon} onClick={() => handleActionClick("view", row.original)} title="View Quotation" />
                    <TrashIcon {...icon} onClick={() => handleActionClick("delete", row.original)} title="Delete Quotation" />
                </div>
            ),
        },
    ];

    return (
        <>
            <ContentHeader
                title="Quotation"
                description="Quotation List"
                showHeader={true}
                showDatePicker={false}
                showSearchInput={false}
                showButton={false}
                btnToShow={headerButton}
            />
            <div className="m-4 flex flex-col rounded h-auto bg-white">
                {isOpenDeleteModal && selectedId && (
                    <DeleteConfirmationModal
                        handleDelete={handleDelete}
                        isOpen={isOpenDeleteModal}
                        togglePopup={toggleDeletePopup}
                        id={selectedId}
                    />
                )}

                <div className="flex justify-end mr-5 mt-4">
                    <Button text="Export to Excel" icon="FaDownload" textColor="white" bgColor="[#39ACE4]" onClick={() => { HandleExport(columnsArray, enquireQuotationList, "EnquireQuotationList") }} />
                </div>
                {isLoading ? (
                    <div className="flex items-center justify-center">
                        <Blocks
                            height="80"
                            width="80"
                            color="#4fa94d"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            visible={true}
                        />
                    </div>
                ) : (
                    enquireQuotationList && <PaginationTable columnsData={columnsArray} rowData={enquireQuotationList} />
                )}
            </div>
        </>
    );
}

export default EnquireQuotationList;
