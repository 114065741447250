import React from 'react';

const Radio = ({ label = "", name, value, onChange, options, alignment = "text-left", required = false }) => {
    return (
        <div className="flex flex-col">
            { label && <label className={`mr-2 text-[12px] ${alignment}`}>{label} {required && <span className="text-red-500 text-[12px] font-extrabold">*</span>}</label> }
            <div className="flex flex-wrap">
                {options.map(option => (
                    <label key={option.value} className="inline-flex items-center mr-4 mb-2 text-[12px]">
                        <input
                            type="radio"
                            name={name}
                            value={option.value}
                            checked={value === option.value}
                            onChange={onChange}
                            className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                        />
                        <span className="ml-2">{option.label}</span>
                    </label>
                ))}
            </div>
        </div>
    );
};

export default Radio;
