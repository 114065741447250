export const DEFAULT_AUTH_CONFIG = {
    headers: {
        Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwidXNlcm5hbWUiOiJtaWNoYWVsdyIsImVtYWlsIjoibWljaGFlbC53aWxsaWFtc0B4LmR1bW15anNvbi5jb20iLCJmaXJzdE5hbWUiOiJNaWNoYWVsIiwibGFzdE5hbWUiOiJXaWxsaWFtcyIsImdlbmRlciI6Im1hbGUiLCJpbWFnZSI6Imh0dHBzOi8vZHVtbXlqc29uLmNvbS9pY29uL21pY2hhZWx3LzEyOCIsImlhdCI6MTcxNzYxMTc0MCwiZXhwIjoxNzE3NjE1MzQwfQ.eQnhQSnS4o0sXZWARh2HsWrEr6XfDT4ngh0ejiykfH8'
    }
};

const LOGIN_FORM_MODEL = {
    username: "",
    password: ""
};

export const INITIAL_LOGIN_FORM_VALUE = LOGIN_FORM_MODEL;

export const LOGIN_REQUIRED_FIELDS = ["username", "password"];

export const LOGIN_ERROR_MESSAGE_VALUES = LOGIN_FORM_MODEL;

const VALIDATE_USER_MODEL = {
    username: ""
};

export const INITIAL_VALIDATE_USER_FORM_VALUE = VALIDATE_USER_MODEL;

export const VALIDATE_USER_REQUIRED_FIELDS = ["username"];

export const VALIDATE_USER_ERROR_MESSAGE_VALUES = VALIDATE_USER_MODEL;

const VALIDATE_OTP_MODEL = {
    otp: ""
};

export const INITIAL_OTP_FORM_VALUE = VALIDATE_OTP_MODEL;

export const OTP_REQUIRED_FIELDS = ["otp"];

export const OTP_ERROR_MESSAGE_VALUES = VALIDATE_OTP_MODEL;

const SET_PWD_MODEL = {
    password: "",
    cpassword: ""
};

export const INITIAL_SET_PWD_FORM_VALUE = SET_PWD_MODEL;

export const SET_PWD_REQUIRED_FIELDS = ["password", "cpassword"];

export const SET_PWD_ERROR_MESSAGE_VALUES = SET_PWD_MODEL;
