export const CATEGORY_INITIAL_FORM_VALUE = {
    name: "",
    description: "",
    isRoot: false,
};

export const CATEGORY_REQUIRED_FIELDS = [
    "name"
];

export const CATEGORY_ERROR_MESSAGE_VALUES = {
    name: ""
};

export const PRODUCT_INITIAL_FORM_VALUE = {
    name: "",
    description: "",
    originalPrice: 0,
    stock: 0,
    code: ""
};

export const PRODUCT_ERROR_MESSAGE_VALUES = {
    name: "",
    description: "",
    code: ""
    //originalPrice: "", Not Required
    //stock: "" Not Required
};

export const PRODUCT_REQUIRED_FIELDS = [
    "name",
    "description",
    //"originalPrice", Not Required
    //"stock" Not Required
];