import PropTypes from "prop-types";
import { scrollToTop } from "@/utils/CustomFunctions";

const APIFailureMessage = ({ apiFailure = "" }) => {
    scrollToTop()
    return (
        <div className="bg-red-500 text-white p-4 fixed top-0 left-0 right-0 z-10">
            {apiFailure !== "" ? apiFailure : "API request failed. Please try again later."}
        </div>
    );
};

APIFailureMessage.propTypes = {
    apiFailure: PropTypes.string
};

export default APIFailureMessage;