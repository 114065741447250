import React, { useState } from "react";
import { set, cloneDeep, isEmpty, get } from "lodash";
import Modal from "react-modal";
import { toast } from "react-toastify";

import InputField from "@/components/InputField"
import Button from "@/components/Button";
import Checkbox from "@/components/Checkbox";
import Dropdown from "@/components/Dropdown";

import { 
    CATEGORY_INITIAL_FORM_VALUE,
    CATEGORY_ERROR_MESSAGE_VALUES,
    CATEGORY_REQUIRED_FIELDS
} from "./constants";
Modal.setAppElement("#root");

const AddCategory = ({ togglePopup, isOpen, categoryNameList, onAddCategorySubmit, selectedRow }) => {

    const [category, setCategory] = useState(!isEmpty(selectedRow) ? selectedRow : CATEGORY_INITIAL_FORM_VALUE);
    const [errorMessages, setErrorMessages] = useState(CATEGORY_ERROR_MESSAGE_VALUES);
    //const [isChecked, setIsChecked] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(!isEmpty(selectedRow) ? { id: selectedRow.id, name: selectedRow.name } : []);

    const handleOptionSelect = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        const updatedState = cloneDeep(category);
        set(updatedState, name, checked);
        setCategory(updatedState);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedState = cloneDeep(category);
        set(updatedState, name, value);
        setCategory(updatedState);
        setErrorMessages((prevErrorMessages) => ({
            ...prevErrorMessages,
            [name]: "",
        }));
    };

    const handleSubmit = async () => {
        const newErrorMessages = {};
        CATEGORY_REQUIRED_FIELDS.forEach((field) => {
            if (!get(category, field)) {
                newErrorMessages[field] = "This field is required.";
            } else {
                newErrorMessages[field] = "";
            }
        });

        setErrorMessages(newErrorMessages);
        if (Object.values(newErrorMessages).some((errorMsg) => errorMsg !== "")) {
            toast.error("Please fill mandatory fields");
            return;
        }
    
        let payLoad = {
            name: category?.name,
            description: category?.description,
            isRoot: category?.isRoot
        };

        if (!isEmpty(selectedRow)) {
            payLoad = {
                ...payLoad,
                parentId: selectedOptions.id
            }
            const data = selectedOptions ? { ...payLoad } : category
            onAddCategorySubmit(data, "patch");
        } else {
            const data = !isEmpty(selectedOptions) ? { ...category, parentId: selectedOptions.id } : category;
            onAddCategorySubmit(data, "post");
        }
    };

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={togglePopup}
                contentLabel="Example Modal"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                className="bg-white p-6 rounded shadow-lg"
            >
                <div className="p-3.5">
                    <span className="text-xl font-small pt-3.5">{!isEmpty(selectedRow) ? "Edit Category" : "Add Category"}</span>
                    <div className="pt-3.5">
                        <Checkbox
                            label="Is Root"
                            checked={get(category, "isRoot")}
                            onChange={handleCheckboxChange}
                            name="isRoot"
                        />
                    </div>
                    {!category?.isRoot && <div className="pt-3.5">
                        <Dropdown
                            options={categoryNameList}
                            selectedOption={selectedOptions}
                            onOptionSelect={handleOptionSelect}
                            label="Select Category"
                            parentClass="my-dropdown"
                            id="my-dropdown"
                        />
                    </div>}
                    <div className="grid grid-cols-2 gap-2 pt-3.5">
                        <div className="">
                            <InputField
                                label="Name"
                                name="name"
                                placeholder="Name"
                                value={category.name}
                                onChange={handleInputChange}
                            />
                            {errorMessages.name && <p className="text-red-500 text-[12px] mt-1">{errorMessages.name}</p>}
                        </div>
                        <div className="">
                            <InputField
                                label="Description"
                                name="description"
                                placeholder="Enter Description"
                                value={category.description}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="flex gap-2 mt-4">
                        <Button text="Submit" textColor="white" bgColor="[#39ACE4]" onClick={() => handleSubmit()} />
                        <Button text="Cancel" textColor="Black" bgColor="White" onClick={() => togglePopup()} />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default AddCategory;
