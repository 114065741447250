export const ENQUIRY_LIST_COLUMN = [
    { 
        Header: 'Sr.No', accessor: 'serialNumber', width: 20
    },
    {
        Header: 'Date', accessor: 'createdOn', width: 10
    },
    { 
        Header: 'Salesperson', accessor: 'salesPersonName', width: 10
    },
    { 
        Header: 'Customer', accessor: 'firstName', width: 10, Cell: ({ row }) => {
            const { firstName, lastName } = row.original;
            return <span>{`${firstName} ${lastName}`}</span>;
        }
    },
    {
        Header: 'Transport', accessor: 'transporterName', width: 25
    },
    { 
        Header: 'Trailer Type', accessor: 'typeOfTrailer', width: 100
    },
    {
        Header: 'Contact', accessor: 'contactMedium.phoneNumber', width: 10
    },
    {
        Header: 'Quotation Id', accessor: 'quotationList[0].quotationId', width: 25
    },
    {
        Header: 'Address', accessor: 'location', width: 100
    },
    {
        Header: 'Competitor', accessor: 'competitor', width: 10
    },
    {
        Header: 'Status', accessor: 'status', width: 10
    },
    {
        Header: 'Quantity', accessor: 'quantity', width: 10
    },
];
