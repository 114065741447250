import { isEmpty, filter, find, map } from "lodash";
import { SIDE_WALL_TYPES } from "./IntialFormValues";

export const generateTemplatePayload = (formValues, dropdownList, action) => {

    try {
        const payload = {};
        payload.additionalInformation = formValues.additionalInformation;
        payload.additionalInformation1 = formValues.additionalInformation;
        payload.application = formValues.application;
        payload.electrical = formValues.electrical;
        payload.note = formValues.note;
        payload.painting = formValues.painting;
        payload.reflectorYellow = formValues.reflectorYellow;
        payload.rupd = formValues.rupd;
        payload.sideMakerTailLamp = formValues.sideMakerTailLamp;
        payload.spareWheelCarrierBkt = formValues.spareWheelCarrierBkt;
        payload.spraySuppressor = formValues.spraySuppressor;
        payload.stickers = formValues.stickers;
        payload.supd = formValues.supd;
        payload.toolBox = formValues.toolBox;
        payload.trailerDescription = formValues.trailerDescription;
        payload.warrenty = parseInt(formValues.warrenty);
        payload.orderGenerateFlag = false;
        payload.status = '';

        const selectedTrailerType = find(dropdownList.trailerType, { productId: formValues.trailerType.productId });
        const selectedTruckModel = find(dropdownList.truckModel, { productId: formValues.truckModel.productId });
        const selectedTwistLock = find(dropdownList.twistLock, { productId: formValues.twistLock.productId });
        const selectedWallType = find(SIDE_WALL_TYPES, { productId: formValues.sideWallType.productId });
        const selectedAxlesMake = find(dropdownList.axlesMake, { productId: formValues.axlesMake.productId });
        const selectedAxlesType = find(dropdownList.axlesType, { productId: formValues.axlesType.productId });
        const selectedTyreMake = find(dropdownList.tyreMake, { productId: formValues.tyre.productId });
        const selectedWheelRim = find(dropdownList.wheelRim, { productId: formValues.wheelRim.productId });
        const selectedFloorSheet = find(dropdownList.floorSheet, { productId: formValues.floorSheet.productId });
        const selectedAddRefmnt = find(dropdownList.additionalReinforcement, { productId: formValues.additionalReinforcement.productId });
        const selectedSuspMake = find(dropdownList.suspensionMake, { productId: formValues.suspensionMake.productId });
        const selectedSuspType = find(dropdownList.suspensionType, { productId: formValues.suspensionType.productId });
        const selectedSuspComp = find(dropdownList.suspensionCombination, { productId: formValues.suspensioncombination.productId });
        const selectedBrakeKit = find(dropdownList.brakeKit, { productId: formValues.brakeKit.productId });
        const selectedBrakeChamber = find(dropdownList.brakeChambers, { productId: formValues.brakeChamber.productId });
        const selectedTieDownHook = find(dropdownList.tieDownHook, { productId: formValues.tieDownHook.productId });
        const selectedCoilLashing = find(dropdownList.coilLashing, { productId: formValues.coilLashing.productId });
        const selectedKhuPillarDetail = find(dropdownList.khutlaPillarDetails, { productId: formValues.khutlaPillarDetails.productId });
        const selectedKingpin = find(dropdownList.kingpin, { productId: formValues.kingpin.productId });
        const selectedKhutlaPocket = find(dropdownList.khutlaPocket, { productId: formValues.khutlaPocket.productId });
        const selectedLandingLeg = find(dropdownList.landingLeg, { productId: formValues.landingLag.productId });
        const selectedMudGaurd = find(dropdownList.mudgaurd, { productId: formValues.mudGuard.productId });
        
        const { radialNylon, ribLug } = formValues.tyre;

        function mapcallback(o) {
            o.selected = false;
            return o; 
        }

        if (selectedKhuPillarDetail) {
            const khutlaPillarDetails = filter(dropdownList.khutlaPillarDetails, function(o) { return o.productId !== selectedKhuPillarDetail.productId });
            payload.khutlaPillarDetails = [{ ...selectedKhuPillarDetail, selected: true }];
            if (khutlaPillarDetails.length > 0) {
                const updateKhuPDtls = map(khutlaPillarDetails, mapcallback);
                payload.khutlaPillarDetails = payload.khutlaPillarDetails.concat(updateKhuPDtls);
            }
        }

        if (selectedCoilLashing) {
            const coilLashing = filter(dropdownList.coilLashing, function(o) { return o.productId !== selectedCoilLashing.productId });
            payload.coilLashing = [{ ...selectedCoilLashing, selected: true }];
            if (coilLashing.length > 0) {
                const updateCoilLash = map(coilLashing, mapcallback);
                payload.coilLashing = payload.coilLashing.concat(updateCoilLash);
            }
        }

        if (selectedTieDownHook) {
            const tieDownHook = filter(dropdownList.tieDownHook, function(o) { return o.productId !== selectedTieDownHook.productId });
            payload.tieDownHook = [{ ...selectedTieDownHook, selected: true }];
            if (tieDownHook.length > 0) {
                const updatedTieDownHook = map(tieDownHook, mapcallback);
                payload.tieDownHook = payload.tieDownHook.concat(updatedTieDownHook);
            }
        }

        if (selectedTwistLock) {
            const twistLock = filter(dropdownList.twistLock, function(o) { return o.productId !== selectedTwistLock.productId });
            payload.twistLock = [{ ...selectedTwistLock, selected: true }];
            if (twistLock.length > 0) {
                const updatedTwistLock = map(twistLock, mapcallback);
                payload.twistLock = payload.twistLock.concat(updatedTwistLock);
            }
        }

        if (selectedBrakeChamber) {
            const brakeChamber = filter(dropdownList.brakeChambers, function(o) { return o.productId !== selectedBrakeChamber.productId });
            payload.brakeChamber = [{ ...selectedBrakeChamber, selected: true }];
            if (brakeChamber.length > 0) {
                const updatedBrakeChamber = map(brakeChamber, mapcallback);
                payload.brakeChamber = payload.brakeChamber.concat(updatedBrakeChamber);
            }
        }

        if (selectedBrakeKit) {
            const brakeKit = filter(dropdownList.brakeKit, function(o) { return o.productId !== selectedBrakeKit.productId });
            payload.brakeKit = [{ ...selectedBrakeKit, selected: true }];
            if (brakeKit.length > 0) {
                const updatedBrakeKit = map(brakeKit, mapcallback);
                payload.brakeKit = payload.brakeKit.concat(updatedBrakeKit);
            }
        }

        if (selectedKingpin) {
            const kingpin = filter(dropdownList.kingpin, function(o) { return o.productId !== selectedKingpin.productId });
            payload.kingpin = [{ ...selectedKingpin, selected: true }];
            if (kingpin.length > 0) {
                const updatedKingpin = map(kingpin, mapcallback);
                payload.kingpin = payload.kingpin.concat(updatedKingpin);
            }
        }

        payload.suspension = {};

        if (selectedSuspComp) {
            const suspensionCombination = filter(dropdownList.suspensionCombination, function(o) { return o.productId !== selectedSuspComp.productId });
            payload['suspension']['combination'] = [{ ...selectedSuspComp, selected: true }];
            if (suspensionCombination.length > 0) {
                const updatedSuspComb = map(suspensionCombination, mapcallback);
                payload['suspension']['combination'] = payload['suspension']['combination'].concat(updatedSuspComb);
            }
        }

        if (selectedSuspType) {
            const suspensionType = filter(dropdownList.suspensionType, function(o) { return o.productId !== selectedSuspType.productId });
            payload['suspension']['type'] = [{ ...selectedSuspType, selected: true }];
            if (suspensionType.length > 0) {
                const updatedSuspType = map(suspensionType, mapcallback);
                payload['suspension']['type'] = payload['suspension']['type'].concat(updatedSuspType);
            }
        }

        if (selectedSuspMake) {
            const suspensionMake = filter(dropdownList.suspensionMake, function(o) { return o.productId !== selectedSuspMake.productId });
            payload['suspension']['make'] = [{ ...selectedSuspMake, selected: true }];
            if (suspensionMake.length > 0) {
                const updatedSuspMake = map(suspensionMake, mapcallback);
                payload['suspension']['make'] = payload['suspension']['make'].concat(updatedSuspMake);
            }
        }

        if (selectedMudGaurd) {
            const mudGuard = filter(dropdownList.mudgaurd, function(o) { return o.productId !== selectedMudGaurd.productId });
            payload.mudGuard = [{ ...selectedMudGaurd, selected: true }];
            if (mudGuard.length > 0) {
                const updatedMudGaurd = map(mudGuard, mapcallback);
                payload.mudGuard = payload.mudGuard.concat(updatedMudGaurd);
            }
        }

        if (selectedKhutlaPocket) {
            const khutlaPocket = filter(dropdownList.khutlaPocket, function(o) { return o.productId !== selectedKhutlaPocket.productId });
            payload.khutlaPocket = [{ ...selectedKhutlaPocket, selected: true }];
            if (khutlaPocket.length > 0) {
                const updatedKhutlaPocket = map(khutlaPocket, mapcallback);
                payload.khutlaPocket = payload.khutlaPocket.concat(updatedKhutlaPocket);
            }
        }

        if (selectedAddRefmnt) {
            const additionalReinforcement = filter(dropdownList.additionalReinforcement, function(o) { return o.productId !== selectedAddRefmnt.productId });
            payload.additionalReinforcement = [{ ...selectedAddRefmnt, selected: true }];
            if (additionalReinforcement.length > 0) {
                const updatedAddRefmnt = map(additionalReinforcement, mapcallback);
                payload.additionalReinforcement = payload.additionalReinforcement.concat(updatedAddRefmnt);
            }
        } else {
            payload.additionalReinforcement = [
                { productId: "9b4c7a25-b8e9-4519-975d-126dff2d2afc", productName: "Reinforcement 1", productPrice: 0, selected: true },
                { productId: "d0386b3e-f2ba-4f1f-ab4b-fa70c106d2b7", productName: "Reinforcement 2", productPrice: 0, selected: false }
            ];
        }

        if (selectedFloorSheet) {
            const floorSheet = filter(dropdownList.floorSheet, function(o) { return o.productId !== selectedFloorSheet.productId });
            payload.floorSheet = [{ ...selectedFloorSheet, selected: true }];
            if (floorSheet.length > 0) {
                const updatedFloorSheet = map(floorSheet, mapcallback);
                payload.floorSheet = payload.floorSheet.concat(updatedFloorSheet);
            }
        }

        if (selectedLandingLeg) {
            const landingLeg = filter(dropdownList.landingLeg, function(o) { return o.productId !== selectedLandingLeg.productId });
            payload.landingLag = [{ ...selectedLandingLeg, selected: true }];
            if (landingLeg.length > 0) {
                const updatedLandingLeg = map(landingLeg, mapcallback);
                payload.landingLag = payload.landingLag.concat(updatedLandingLeg);
            }
        }

        if (selectedWheelRim) {
            const wheelRim = filter(dropdownList.wheelRim, function(o) { return o.productId !== selectedWheelRim.productId });
            payload.wheelRim = [{ ...selectedWheelRim, selected: true }];
            if (wheelRim.length > 0) {
                const updatedWheelRim = map(wheelRim, mapcallback);
                payload.wheelRim = payload.wheelRim.concat(updatedWheelRim);
            }
        }

        payload.tyre = {};

        if (selectedTyreMake) {
            const tyreMake = filter(dropdownList.tyreMake, function(o) { return o.productId !== selectedTyreMake.productId });
            payload['tyre']['make'] = [{ ...selectedTyreMake, selected: true }];
            if (tyreMake.length > 0) {
                const updatedTyreMake = map(tyreMake, mapcallback);
                payload['tyre']['make'] = payload['tyre']['make'].concat(updatedTyreMake);
                payload['tyre'].radial = formValues.tyre?.radialNylon === 'Radial' ? true : false;
                payload['tyre'].nylon = formValues.tyre?.radialNylon === 'Nylon' ? true : false;
            }
        }

        payload.axlesMake = {};

        if (selectedAxlesType) {
            const axlesType = filter(dropdownList.axlesType, function(o) { return o.productId !== selectedAxlesType.productId });
            payload['axlesMake']['type'] = [{ ...selectedAxlesType, selected: true }];
            if (axlesType.length > 0) {
                const updatedAxlesType = map(axlesType, mapcallback);
                payload['axlesMake']['type'] = payload['axlesMake']['type'].concat(updatedAxlesType);;
            }
        }

        if (selectedAxlesMake) {
            const axlesMake = filter(dropdownList.axlesMake, function(o) { return o.productId !== selectedAxlesMake.productId });
            payload['axlesMake']['make'] = [{ ...selectedAxlesMake, selected: true }];
            if (axlesMake.length > 0) {
                const updatedAxlesMake = map(axlesMake, mapcallback);
                payload['axlesMake']['make'] = payload['axlesMake']['make'].concat(updatedAxlesMake);
            }
        }

        if (selectedTruckModel) {
            const truckModel = filter(dropdownList.truckModel, function(o) { return o.productId !== selectedTruckModel.productId });
            payload.truckModel = [{ ...selectedTruckModel, selected: true }];
            if (truckModel.length > 0) {
                const updatedTruckModel = map(truckModel, mapcallback);
                payload.truckModel = payload.truckModel.concat(updatedTruckModel);
            }
        }

        if (selectedWallType) {
            const wallTypes = filter(SIDE_WALL_TYPES, function(o) { return o.productId !== selectedWallType.productId });
            payload.sideWallType = [{ ...selectedWallType, selected: true }];
            if (wallTypes.length > 0) {
                const updatedWallTypes = map(wallTypes, mapcallback);
                payload.sideWallType = payload.sideWallType.concat(updatedWallTypes);
            }
        }

        if (selectedTrailerType) {
            const trailerTypes = filter(dropdownList.trailerType, function(o) { return o.productId !== selectedTrailerType.productId });
            payload.trailerType = [{ ...selectedTrailerType, selected: true }];
            if (trailerTypes.length > 0) {
                const updateTrailerTypes = map(trailerTypes, mapcallback);
                payload.trailerType = payload.trailerType.concat(updateTrailerTypes);
            }
        }

        return payload;
    } catch(err) {
        console.log(err);
        return null;
    }
}