import { ToastContainer } from "react-toastify";
import { Routes, Route, Navigate } from "react-router-dom";
import { Blocks } from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";

import { useData } from "@/hooks/useData";
import { APP_URLS } from "@/routes";

//PROTECTED ROUTE
import ProtectedRoute from "@/layouts/ProtectedRoute";

//AUTH
import { Login, ResetPassword } from "./pages/Auth";

//DASHBOARD
import Dashboard from '@/pages/Dashboard';

//USER ACCOUNT
import UserAccounts from "@/pages/UserAccounts";

//ENQUIRY
import EnquiryList from "@/pages/Enquiry";
import { EnquireQuotation, EnquireQuotationList } from "@/pages/EnquireQuotation";
import { ViewQuotation } from "@/pages/ViewQuotation";

//DESIGN
import { DesignWorkOrderList } from "@/pages/DesignDepartment";

//PRODUCTION
import { ProductionWorkOrderList } from "@/pages/ProductionDepartment";

//QUALITY DISPATCH
import { QualityWorkOrderList } from "@/pages/QualityDispatch";

//ACCOUNT
import AccountDepartment from "@/pages/AccountDepartment";

//ADMIN
import { WorkOrderContainer } from "@/pages/AdminApproval";

//MASTER
import { Category, Product } from "@/pages/Inventory";

//SETTING
import Settings from "@/pages/Settings";

import Reports from "@/pages/Reports";

//COMMENTED
import { QuotationTemplateList, AddQuotationTemplate } from "@/pages/QuotationTemplate";

//WORK ORDER
import { EditWorkOrder, ViewWorkOrder, WorkOrderList, ViewWorkOrderList } from "@/pages/WorkOrder";

import "./App.css";

const App = () => {
  const data = useData();

  return (
    <>
      { data.isLoading ? (
        <div className="flex items-center justify-center">
          <Blocks
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              visible={true}
          />
        </div>
      ) : (
        <>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            limit={1}
          />
          <Routes>
           <Route exact path="/" element={<Navigate to="/login" />}   />
           <Route path="/dashboard/*" element={<ProtectedRoute />}>
             <Route exact path={APP_URLS.HOME} element={<Dashboard />} />

             <Route exact path={APP_URLS.USR_ACCT} element={<UserAccounts />} />
             <Route exact path={APP_URLS.ACCT_DEPT} element={<AccountDepartment />} />
             <Route exact path={APP_URLS.DESN_DEPT} element={<DesignWorkOrderList />} />
             <Route exact path={APP_URLS.PROD_DEPT} element={<ProductionWorkOrderList />} />
             <Route exact path={APP_URLS.QLTY_DISPATCH} element={<QualityWorkOrderList />} />

             <Route exact path={APP_URLS.SALES_ENQ_LIST} element={<EnquiryList />} />
             <Route exact path={APP_URLS.SALES_ENQ_QUO_LIST} element={<EnquireQuotationList />} />
             <Route exact path={APP_URLS.SALES_ENQ_QUO} element={<EnquireQuotation />} />
             <Route exact path={APP_URLS.SALES_WO_LIST} element={<WorkOrderList />} />
             <Route exact path={APP_URLS.SALE_VIEW_WO_LIST} element={<ViewWorkOrderList />} />

             <Route exact path={APP_URLS.ADMIN_QUO_TEMP_LIST} element={<QuotationTemplateList />} />
             <Route exact path={APP_URLS.ADMIN_QUO_TEMP_ADD} element={<AddQuotationTemplate />} />
             <Route exact path={APP_URLS.ADMIN_WO_LIST} element={<WorkOrderContainer />} />

             <Route exact path={APP_URLS.EDIT_WO} element={<EditWorkOrder />} />
             <Route exact path={APP_URLS.VIEW_WO} element={<ViewWorkOrder />} />

             <Route exact path={APP_URLS.VIEW_QUO} element={<ViewQuotation />} />
             <Route exact path={APP_URLS.REPORT} element={<Reports />} />

             <Route exact path={APP_URLS.CATEGORY} element={<Category />} />
             <Route exact path={APP_URLS.PRODUCT} element={<Product />} />

             <Route exact path={APP_URLS.SETTGS} element={<Settings />} />
           </Route>
           <Route path="/login" element={<Login />} />
           <Route path="/password/reset" element={<ResetPassword />} />
           <Route path="*" element={<p>There is nothing here: 404!</p>} />
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
