import React, { useState } from "react";
import moment from "moment/moment";
import { useNavigate, useLocation, Link } from "react-router-dom";

import Button from "@/components/Button";
import SearchInput from "@/components/SearchInput";
//import DatePicker from "@/components/DatePicker";

import MyDatePicker from "@/components/DatePickerComponent";
import './ContentHeader.css';

const ContentHeader = ({ title, description, showHeader, showDatePicker, showSearchInput, showButton, btnToShow, filterDataFunction }) => {

    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState(null);
    const handleDateChange = (name, date) => {
        setSelectedDate(date)
        // const dateconverted = moment(date, "ddd MMM DD YYYY HH:mm:ss ZZ");
        // console.log(dateconverted.format()); // Output: 2023-07-30T00:00:00+05:30
    };

    return (
        <div className="grid-container-custom p-3.5">
            {
                showHeader && <div className="grid-header-item text-left">
                    <span className="w-full text-black text-[20px] font-bold inline-block whitespace-nowrap">{title}</span>
                    <span className="text-[#B9B9B9] text-[14px] inline-block whitespace-nowrap">{description}</span>
                </div>
            }
            <div className="grid-col-3 gap-4 w-full flex item-center justify-end">
                {showDatePicker && <>
                    <MyDatePicker name="date" selectedDate={selectedDate} handleDateChange={handleDateChange} /> </>
                }
                {/* {showDatePicker && <DatePicker />} */}
                {showSearchInput && <div className="grid-item">
                    <SearchInput
                        placeholder="'Type keyword'"
                        filterDataFunction={filterDataFunction}
                    />
                </div>}
                {showButton && <div className="grid-item-last">
                    {btnToShow.showAdd && <button type="button" className="text-black bg-white text-[18px] text-600 px-5 py-2.5 text-center mr-2 mb-2">+ Add</button>}
                    {btnToShow.showGenerateBtn && <button type="button" className="text-black bg-white text-sm px-5 py-2.5 text-center mr-2 mb-2">Generate</button>}
                    {btnToShow.showExportBtn && <button type="button" className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium text-sm px-5 py-2.5 text-center mr-2 mb-2">Export</button>}
                    {btnToShow.showBackBtn && <Button text="Back" icon="" textColor="white" bgColor="[#39ACE4]" onClick={() => navigate(-1)} />}
                </div>}
            </div>
        </div>
    )
}

export default ContentHeader;
