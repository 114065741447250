const columns = [
    {
        Header: 'Serial No',
        accessor: 'serialNo',
        width: 2
    },
    {
        Header: 'Trailer Description',
        accessor: 'trailerDescription',
        width: 10
    },
    {
        Header: 'Application',
        accessor: 'application',
        width: 10,
    },
    {
        Header: 'Additional Information',
        accessor: 'additionalInformation',
        width: 10,
    },
];

export default columns;
