import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import ValidateUser from "./ValidateUser";
import ValidatePasswordOTP from "./ValidatePasswordOTP";
import SetNewPassword from "./SetNewPassword";

const baImage = "/images/login_bg.jpg";

const ResetPassword = () => {

    const navigate = useNavigate();
    const [username, setUsername] = useState("");

    // GET_USER - get user using username
    // VALIDATE_OTP - validate otp for user
    // SET_PASSWORD - after otp validation set new password
    const [step, setStep] = useState("GET_USER");

    const onPasswordStepChanged = async (action, data) => {
        if (action === "VALIDATE_OTP") {
            setStep(action);
            setUsername(data);
        }

        if (action === "SET_PASSWORD") {
            setStep("SET_PASSWORD");
        }
    };

    return (
        <div className="min-h-screen bg-cover bg-center flex items-center justify-center"
            style={{
                backgroundImage: `url(${baImage})`
            }}>
            <div className="flex flex-col">
                <div className="flex flex-row items-center logos mb-2">
                    <img src="/images/autocraft_group.png" className="border-r-2 border-gray-300" />
                    <img src="/images/autocraft_trailers.png" />
                </div>
                <div className="w-full max-w-md p-8 rounded-lg bg-[#39ACE4]">
                    {
                        step === "GET_USER" && <ValidateUser onStepChanged={onPasswordStepChanged} username={username} />
                    }
                    {
                        step === "VALIDATE_OTP" && <ValidatePasswordOTP onStepChanged={onPasswordStepChanged} username={username} />
                    }
                    {
                        step === "SET_PASSWORD" && <SetNewPassword onStepChanged={onPasswordStepChanged} username={username} />
                    }
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;