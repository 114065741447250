import { isEmpty, map } from "lodash";

const USER_COLUMNS = [
    {
        Header: 'Sr. No',
        accessor: 'serialNumber',
        width: 5
    },
    {
        Header: 'Name',
        accessor: 'fullName',
        width: 10
    },
    {
        Header: 'Username',
        accessor: 'userId',
        width: 25
    },
    {
        Header: 'Role',
        accessor: 'roleId',
        width: 25,
        Cell: ({ row }) => {
            const { roleDetails } = row.original;
            if (isEmpty(roleDetails)) {
                return '';
            }
            return <span>{`${map(roleDetails, "roleName").join(", ")}`}</span>;
        }
    },
    {
        Header: 'Email',
        accessor: 'contactMedium.email',
        width: 25
    },
    {
        Header: 'Contact',
        accessor: 'contactMedium.phoneNumber',
        width: 25
    }
];

const ROLE_COLUMNS = [
    {
        Header: 'Sr. No',
        accessor: 'serialNumber',
        width: 5
    },
    {
        Header: 'Role',
        accessor: 'name',
        width: 10
    },
    {
        Header: 'Description',
        accessor: 'description',
        width: 25
    },
    {
        Header: 'Status',
        accessor: 'status',
        width: 25
    },
]

export { USER_COLUMNS, ROLE_COLUMNS };
