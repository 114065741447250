import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Select from "react-select";
import { toast } from "react-toastify";
import { set, cloneDeep, isEmpty, get, pull, concat } from "lodash";

import InputField from "@/components/InputField"
import Button from "@/components/Button";
import { 
    PRODUCT_INITIAL_FORM_VALUE,
    PRODUCT_ERROR_MESSAGE_VALUES,
    PRODUCT_REQUIRED_FIELDS
} from "./constants";
import { REGEX } from "@/utils/AppConstants";
Modal.setAppElement("#root");

const AddProduct = ({ togglePopup, isOpen, categoryNameList, onAddProductSubmit, selectedRow }) => {
    const [product, setProduct] = useState(!isEmpty(selectedRow) ? selectedRow : PRODUCT_INITIAL_FORM_VALUE);
    const [errorMessages, setErrorMessages] = useState(PRODUCT_ERROR_MESSAGE_VALUES);
    const [selectedOptions, setSelectedOptions] = useState(!isEmpty(selectedRow) ? { value: selectedRow.categoryId, label: selectedRow.categoryName } : []);
    let PRODUCT_FORM_REQUIRED_FIELDS = PRODUCT_REQUIRED_FIELDS;

    // define the function to handle option selection
    const handleOptionSelect = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedState = cloneDeep(product);
        set(updatedState, name, value);
        setProduct(updatedState);
    };

    const makeDependentFieldsMandatory = () => {
        if (!isEmpty(selectedOptions)) {
            if (selectedOptions.label === "Trailer Type" && selectedOptions.value === "01") {
                PRODUCT_FORM_REQUIRED_FIELDS = concat(PRODUCT_FORM_REQUIRED_FIELDS, "code");
            } else {
                pull(PRODUCT_FORM_REQUIRED_FIELDS, "code");
            }
        };
    };

    const handleSubmit = async () => {
        makeDependentFieldsMandatory();

        const newErrorMessages = {};
        PRODUCT_FORM_REQUIRED_FIELDS.forEach((field) => {
            if (!get(product, field)) {
                newErrorMessages[field] = "This field is required.";
            } else {
                newErrorMessages[field] = "";
                if (field === "originalPrice" || field === "stock") {
                    if (!get(product, field).toString().match(REGEX.WHOLE_NUMBER)) {
                        newErrorMessages[field] = "Please enter valid input";
                    }
                };
            }
        });

        setErrorMessages(newErrorMessages);
        if (Object.values(newErrorMessages).some((errorMsg) => errorMsg !== "")) {
            toast.error("Please fill mandatory fields");
            return;
        }

        const payLoad = {
            name: product?.name,
            description: product?.description,
            originalPrice: product?.originalPrice,
            stock: product?.stock,
            code: product?.code
        };

        if (!isEmpty(selectedRow)) {
            const payload = {
                name: product?.name,
                description: product?.description,
                categoryId: selectedOptions.value,
                originalPrice: 1.0,
                stock: product?.stock,
                code: product?.code
            };

            //const data = selectedOptions ? { ...payload, productCode: product?.name } : payload;
            const data = { ...payload };
            onAddProductSubmit(data, "patch");
        } else {
            const data = selectedOptions ? { ...payLoad, categoryId: selectedOptions.value } : payLoad;
            onAddProductSubmit(data, "post");
        }
    };

    useEffect(() => {
        makeDependentFieldsMandatory();
    }, []);

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={togglePopup}
                contentLabel="Example Modal"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                className="bg-white p-6 rounded shadow-lg"
            >
                <div className="pt-3.5">
                    <span className="text-xl font-small pt-3.5 ">{!isEmpty(selectedRow) ? "Edit Inventory" : "Add Inventory"}</span>
                    <div className="pt-3.5">
                        <Select
                            options={categoryNameList}
                            isMulti={false}
                            value={selectedOptions}
                            label="Select Options"
                            id="prodOptions"
                            onChange={handleOptionSelect}
                            required={true}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontSize: "12px",
                                    borderColor: "rgb(156 163 175)",
                                    "&:focus": { borderColor: "rgb(156 163 175)", outlineColor: "rgb(156 163 175)" }
                                }),
                                option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontSize: "12px",
                                })
                            }}
                        />
                        {errorMessages.catergory && <p className="text-red-500 text-[12px] mt-1">{errorMessages.catergory}</p>}
                    </div>
                    <div className="grid grid-cols-2 gap-2 pt-3.5 ">
                        <div className="">
                            <InputField
                                name="name"
                                label="Name"
                                placeholder="Name"
                                value={product.name}
                                onChange={handleInputChange}
                                required={true}
                            />
                            {errorMessages.name && <p className="text-red-500 text-[12px] mt-1">{errorMessages.name}</p>}
                        </div>
                        <div className="">
                            <InputField
                                name="description"
                                label="Description"
                                placeholder="Enter Description"
                                value={product.description}
                                onChange={handleInputChange}
                                required={true}
                            />
                            {errorMessages.description && <p className="text-red-500 text-[12px] mt-1">{errorMessages.description}</p>}
                        </div>
                        <div className="">
                            <InputField
                                name="code"
                                label="Code"
                                placeholder="Enter Code"
                                value={product.code}
                                onChange={handleInputChange}
                                required={selectedOptions.label === "Trailer Type" && selectedOptions.value === "01"}
                            />
                            {errorMessages.code && <p className="text-red-500 text-[12px] mt-1">{errorMessages.code}</p>}
                        </div>
                    </div>
                    {/* <div className="grid grid-cols-2 gap-2 pt-3.5 ">
                        <div className="">
                            <InputField
                                name="originalPrice"
                                label="Original Price"
                                placeholder="Original Price"
                                value={product.originalPrice}
                                onChange={handleInputChange}
                                required={true}
                            />
                            {errorMessages.originalPrice && <p className="text-red-500 text-[12px] mt-1">{errorMessages.originalPrice}</p>}
                        </div>
                        <div className="">
                            <InputField
                                name="stock"
                                label="Stock"
                                placeholder="Enter Stock"
                                value={product.stock}
                                onChange={handleInputChange}
                                required={true}
                            />
                            {errorMessages.stock && <p className="text-red-500 text-[12px] mt-1">{errorMessages.stock}</p>}
                        </div>
                    </div> */}
                </div>
                <div className="flex gap-2 mt-2">
                    <Button text="Submit" textColor="white" bgColor="[#39ACE4]" onClick={() => handleSubmit()} />
                    <Button text="Cancel" textColor="Black" bgColor="White" onClick={() => togglePopup()} />
                </div>
            </Modal>
        </div>
    );
};

export default AddProduct;