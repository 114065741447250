import { useNavigate } from "react-router-dom";

export const isAdminLogged = () => {
    const data = JSON.parse(sessionStorage.getItem("user"));
    if (data) {
        return data.roleList.includes("Admin");
    }
    return false;
};

export const isLoginUser = () => {
    const navigate = useNavigate();
    if (!sessionStorage.getItem("authenticated") && !window.location.href.includes("login") && !window.location.href.includes("resetPassword")) {
        navigate("/login");
    }
}

/** add serial number columns */
export const addSerialNumbersToList = async (dataList) => {
    return dataList.map((item, index) => {
        item["serialNumber"] = index + 1;
        return item;
    });
};

/** filter orderList base on customer name , quantity and product name function */
export const filterOrders = (searchInput, filterData) => {
    if (!searchInput) {
        return [];
    }

    const searchText = searchInput.toLowerCase();
    return filterData && filterData.filter(order => {
        const customerName = order?.customerName?.toLowerCase();
        const productName = order?.trailerType?.productName?.toLowerCase();
        const quantity = order?.quantity?.toString();

        return customerName?.includes(searchText) || productName?.includes(searchText) || quantity?.includes(searchText);
    });
}

/** filter orderList base on customer name , quantity and product name function */
export const filterCategoryData = (searchInput, filterData) => {
    if (!searchInput) {
        return [];
    }

    const searchText = searchInput.toLowerCase();
    return filterData.filter(order => {
        const fieldsToSearch = ["name", "description"];
        return fieldsToSearch.some(field => order[field].toLowerCase().includes(searchText));
    });
};


/** filter orderList base on customer name , quantity and product name function */
export const filterInventryData = (searchInput, filterData) => {
    if (!searchInput) {
        return [];
    }

    const searchText = searchInput.toLowerCase();
    return filterData && filterData.filter(order => {
        const name = order?.name?.toLowerCase();
        const categoryName = order?.categoryName?.toLowerCase();
        const desc = order?.description?.toLowerCase();
        const originalPrice = order?.originalPrice?.toString();
        const stock = order?.stock?.toString();
        return (
            stock?.includes(searchText) ||
            categoryName?.includes(searchText) ||
            originalPrice?.includes(searchText) ||
            name?.includes(searchText) ||
            desc?.includes(searchText)
        );
    });
}

export const filterEnquiry = (searchInput, filterData) => {
    if (!searchInput) {
        return [];
    }

    const searchText = searchInput.toLowerCase();
    return filterData && filterData.filter(order => {
        const customerName = order.firstName.toLowerCase();
        const transporterName = order.transporterName.toLowerCase();
        const typeOfTrailer = order.typeOfTrailer.toLowerCase();
        const phoneNumber = order.contactMedium.phoneNumber.toLowerCase();
        const email = order.contactMedium.email.toLowerCase();
        const location = order.location.toLowerCase();
        const competitor = order.competitor.toLowerCase();
        const quantity = order.quantity.toString();
        const salesPersonName = order?.salesPersonName?.toLowerCase();
        return (
            customerName.includes(searchText) ||
            transporterName.includes(searchText) ||
            typeOfTrailer.includes(searchText) ||
            phoneNumber.includes(searchText) ||
            email.includes(searchText) ||
            location.includes(searchText) ||
            quantity.includes(searchText) ||
            competitor.includes(searchText) ||
            salesPersonName?.includes(searchText)
        );
    });
}

/** filter userList based on user full name , user name , role, email and phone number function */
export const filterUserData = (searchInput, filterData) => {
    if (!searchInput) {
        return [];
    }

    const searchText = searchInput.toLowerCase();
    return filterData && filterData.filter(user => {
        // Assuming you want to search in full name , user name , role, email and phone number fields
        const fullName = user?.fullName.toLowerCase();
        const givenName = user?.givenName.toLowerCase();
        const familyName = user?.familyName.toString();
        const email = user?.contactMedium?.email.toString();
        const phoneNumber = user?.contactMedium?.phoneNumber.toString();
        return (
            fullName.includes(searchText) ||
            givenName.includes(searchText) ||
            familyName.includes(searchText) ||
            email.includes(searchText) ||
            phoneNumber.includes(searchText)
        );
    });
}

/** role wise module permission */
export const RolePermissionChecker = (roleName) => {
    const user = JSON.parse(sessionStorage.getItem("user"));

    if (user?.rolePermission) {
        const isModulePresent = user?.rolePermission.some(role => {
            return role.modulePermission.some(permission => permission.moduleName?.toLowerCase() === roleName?.toLowerCase());
        });
        return isModulePresent;
    }

    return false;
}

/* function to filter menu which user having access */
export const getVisibleMenus = (routes) => {

    const visibleMenus = [];
    for (const route of routes) {
        const pages = route.pages.filter(page =>  {
            if (page.allowedTo === "All") {
                return true;
            }
            return RolePermissionChecker(page.allowedTo);
        });

        if (pages.length > 0) {
            visibleMenus.push({
                layout: route.layout,
                pages: pages
            });
        }
    }

    return visibleMenus;
};

export const getDropdownOptions = async (response) => {
    const options = {};
    const firstLetterToLower = (str) => {
        return str.charAt(0).toLowerCase() + str.slice(1).replace(/\s/g, '');
    };

    response.forEach((category) => {
        if (category.mainCategory && category.productList) {
            options[firstLetterToLower(category.name)] = category.productList;
        } else if (category.subCategory) {
            category.subCategory.forEach((subCategory) => {
                if (subCategory.productList) {
                    options[firstLetterToLower(subCategory.name)] = subCategory.productList;
                }
            });
        }
    });

    return options;
};

export const scrollToTop = () => {
    window.scrollTo(0, 0);
};
