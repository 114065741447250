const AccountDepartment = () => {
    return (
        <div className="m-4 rounded h-auto">
            <div className="p-5 flex items-center justify-center">
                <h1 className="text-left text-[22px]">Coming Soon...</h1>
            </div>
        </div>
    );
};

export default AccountDepartment;
