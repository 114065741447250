import React, { useEffect, useState } from "react";
import axios from "axios";
import { get } from "lodash";
import { toast } from "react-toastify";
import { HiOutlineEyeOff, HiOutlineEye } from "react-icons/hi";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

import { loginServiceApi } from "@/utils/API";
import { 
    INITIAL_SET_PWD_FORM_VALUE,
    SET_PWD_REQUIRED_FIELDS,
    SET_PWD_ERROR_MESSAGE_VALUES,
    DEFAULT_AUTH_CONFIG
} from "../constant";
import { APP_URLS } from "@/routes";

const SetNewPassword = ({ onStepChanged, username }) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const [formValue, setFormValue] = useState(INITIAL_SET_PWD_FORM_VALUE);
    const [errorMessages, setErrorMessages] = useState(SET_PWD_ERROR_MESSAGE_VALUES);

    const handleChange = e => {
        setFormValue({
            ...formValue,
            [e.target.name]: e.target.value
        })
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newErrorMessages = {};
        const requiredFields = SET_PWD_REQUIRED_FIELDS;
        requiredFields.forEach((field) => {
            if (!get(formValue, field)) {
                newErrorMessages[field] = "This field is required.";
            } else {
                newErrorMessages[field] = "";
                if (get(formValue, "password").trim().length > 15) {
                    newErrorMessages["password"] = "Password should be maximum of 15 characters";
                }
                if (get(formValue, "password").trim() !== get(formValue, "cpassword").trim()) {
                    newErrorMessages["cpassword"] = "Passwords does not matched";
                }
            }
        });

        setErrorMessages(newErrorMessages);
        if (Object.values(newErrorMessages).some((errorMsg) => errorMsg !== "")) {
            return;
        };
        
        try {
            setLoading(true);
            let payload = {
                newPassword: formValue?.password,
                userId: username
            };
            let response = await axios.patch(`${loginServiceApi.resetPassword}`, payload, DEFAULT_AUTH_CONFIG);
            setLoading(false);
            if (response?.status === 200) {
                toast.success(`Password Changed`);
                navigate(`${APP_URLS.LOGIN}`);
            } else {
                toast.error(response?.message || "Error, Password not changed. Please try again!");
            }
        } catch (error) {
            setLoading(false);
            toast.error("Error, Please try again!");
        }
    };

    return (
        <>
            <h1 className="text-white text-3xl font-semibold text-center mb-2">New Password</h1>
            <form className="space-y-6">
                <div className="flex items-center rounded-md bg-white border border-gray-300">
                    <span className="inline-flex items-center justify-center rounded-l-md px-2">
                        {!showPassword && <HiOutlineEyeOff className="w-6 h-6 text-gray-400 cursor-pointer" onClick={(e) => setShowPassword(true)} />}
                        {showPassword && <HiOutlineEye className="w-6 h-6 text-gray-400 cursor-pointer" onClick={(e) => setShowPassword(false)} />}
                    </span>
                    <input
                        type={!showPassword ? "password": "text"}
                        id="password"
                        name="password"
                        className="flex-grow w-full px-4 py-2 text-gray-700 placeholder-gray-500 border-gray-300 rounded-r-md focus:outline-none"
                        placeholder="Password"
                        value={formValue.password}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                    />
                </div>
                {errorMessages.password && <p className="text-red-500 text-left text-[12px]">{errorMessages.password}</p>}
                <div className="flex items-center rounded-md bg-white border border-gray-300">
                    <span className="inline-flex items-center justify-center rounded-l-md px-2">
                        {!showCPassword && <HiOutlineEyeOff className="w-6 h-6 text-gray-400 cursor-pointer" onClick={(e) => setShowCPassword(true)} />}
                        {showCPassword && <HiOutlineEye className="w-6 h-6 text-gray-400 cursor-pointer" onClick={(e) => setShowCPassword(false)} />}
                    </span>
                    <input
                        type={!showCPassword ? "password": "text"}
                        id="cpassword"
                        name="cpassword"
                        className="flex-grow w-full px-4 py-2 text-gray-700 placeholder-gray-500 border-gray-300 rounded-r-md focus:outline-none"
                        placeholder="Confirm Password"
                        value={formValue.cpassword}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                    />
                </div>
                {errorMessages.cpassword && <p className="text-red-500 text-left text-[12px]">{errorMessages.cpassword }</p>}
                {!loading && <button
                    className="w-full bg-[#FF9220] hover:bg-orange-500 text-white font-bold py-2 px-2 rounded-lg h-12"
                    onClick={handleSubmit}
                    type="submit"
                >
                    Submit
                </button>}
                {loading && <div className="grid justify-items-center">
                    <ColorRing
                        visible={loading}
                        height="80"
                        width="80"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper text-center"
                        colors={["#FF9220", "#FF9220", "#FF9220", "#FF9220", "#FF9220"]}
                    />
                </div>}
            </form>
        </>
    );
}

export default SetNewPassword;
