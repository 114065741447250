import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { set, cloneDeep, get, isEmpty } from "lodash";
import { toast } from 'react-toastify';

import Radio from "@/components/Radio";
import Button from "@/components/Button";
import OrderDropdown from "@/components/OrderDropdown";
import InputField from '@/components/InputField';
import TextAreaField from "@/components/TextAreaField";
import ApiFailureMessage from "@/components/APIFailure";
import ContentHeader from '@/pages/ContentHeader';

import { APIService } from '@/services';
import { getOrderDataApi, quotationTemplateApi } from '@/utils/API';
import { scrollToTop } from "@/utils/CustomFunctions";

import { INITIAL_DROPDOWN_OPTIONS, ERROR_MESSAGE_VALUES } from '../EnquireQuotation/constants';
import { PAYLOAD, INITIAL_FORM_VALUE, SIDE_WALL_TYPES } from './IntialFormValues';

import { generateTemplatePayload } from './GenerateTemplatePayload';

const radioOptionsRadialNylon = [
    { value: 'Radial', label: 'Radial' },
    { value: 'Nylon', label: 'Nylon' },
];

const radioOptionsRibLug = [
    { value: 'Rib', label: 'Rib' },
    { value: 'Lug', label: 'Lug' },
];

const AddQuotationTemplate = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [formValues, setFormValues] = useState(INITIAL_FORM_VALUE);
    const [isDropDownDataAvailable, setIsDropDOwnDataAvailable] = useState(false);
    const [dropdownOptions, setDropdownOptions] = useState(INITIAL_DROPDOWN_OPTIONS);
    const [errorMessages, setErrorMessages] = useState(ERROR_MESSAGE_VALUES);
    const [apiFailure, setApiFailure] = useState(null);
    const sideWallTypeDropDownValue = SIDE_WALL_TYPES;

    const handleRadioOptionChange = event => {
        const updatedState = cloneDeep(formValues);
        set(updatedState, event.target.name, event.target.value);
        setFormValues(updatedState);
    };

    const handleOptionSelect = (option, id) => {
        const updatedState = cloneDeep(formValues);
        set(updatedState, id, option);
        setFormValues(updatedState);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        const updatedState = cloneDeep(formValues); // Create a deep copy of the object
        set(updatedState, name, value); // Set the value of 'nested.key' using lodash set

        setFormValues(updatedState);
        // clear the error message for the field when the user inputs something
        setErrorMessages((prevErrorMessages) => ({
            ...prevErrorMessages,
            [name]: '',
        }));
    };

    const checkErrors = async (data) => {
        const requiredFields = ['customerName', 'axlesMake.productName', 'axlesType.productName', 'suspensionType.productName', 'suspensionMake.productName', 'tyre.radialNylon'];
        const newErrorMessages = {};

        requiredFields.forEach((field) => {
            if (!get(data, field)) {
                newErrorMessages[field] = 'This field is required..';
            } else {
                newErrorMessages[field] = '';
            }
        });

        setErrorMessages(newErrorMessages);
    }

    const handleSubmit = async (formValues) => {
        console.log(formValues);
        await checkErrors(formValues);
        if (Object.values(errorMessages).some((errorMsg) => errorMsg !== '')) {
            scrollToTop();
            return;
        };

        const payload = generateTemplatePayload(formValues, dropdownOptions, 'new');
        const response = await APIService.post(quotationTemplateApi.post, payload);
        if (response?.status === 200 || response?.status === 201) {
            console.log(payload);
            toast.success('Successfully saved');
            navigate('/dashboard/AdminDepartment/QuotationTemplates');
        } else {
            toast.error('Error while saving template');
        }        
    };

    const getDropDownData = async () => {
        const response = await APIService.get(getOrderDataApi.get);
        const productData = {};

        const firstLetterToLower = (str) => {
            return str.charAt(0).toLowerCase() + str.slice(1).replace(/\s/g, '');
        };

        response.forEach((category) => {
            if (category.mainCategory && category.productList) {
                productData[firstLetterToLower(category.name)] = category.productList;
            } else if (category.subCategory) {
                category.subCategory.forEach((subCategory) => {
                    if (subCategory.productList) {
                        productData[firstLetterToLower(subCategory.name)] = subCategory.productList;
                    }
                });
            }
        });

        setDropdownOptions(productData);
        setIsDropDOwnDataAvailable(true);
        console.log(productData);
    };

    useEffect(() => {
        getDropDownData();
    }, [])

    return (
        <>
            <ContentHeader
                title="Quotation Template"
                description="Add Quotation Template"
                showHeader={true}
                showDatePicker={false}
                showSearchInput={false}
                showButton={false}
            />
            {apiFailure && <ApiFailureMessage apiFailure={apiFailure} />}

            {isDropDownDataAvailable && <div className="m-4 flex flex-col rounded h-auto bg-white">
                <div className="flex p-2.5 justify-between items-center">
                    <Button text="Back" icon="" textColor="white" bgColor="[#39ACE4]" onClick={() => navigate(-1)} />
                    <div className="flex space-x-4">
                        <Button text="Submit" icon="" textColor="white" bgColor="[#39ACE4]" onClick={() => handleSubmit()} />
                    </div>
                </div>

                <span className="text-[12px] pt-3.5 pl-3.5 uppercase text-left">Description</span>
                <div className="grid gap-x-8 p-3.5">
                    <div className="grid grid-cols-2 gap-4">
                        <div className="mb-4">
                            <OrderDropdown
                                options={dropdownOptions?.trailerType ?? []}
                                selectedOption={get(formValues, "trailerType")}
                                onOptionSelect={handleOptionSelect}
                                label="TRAILER TYPE"
                                name="TRAILER TYPE"
                                id="trailerType"
                            />
                        </div>
                        <div className="mb-4">
                            <OrderDropdown
                                options={sideWallTypeDropDownValue ?? []}
                                selectedOption={get(formValues, "sideWallType")}
                                onOptionSelect={handleOptionSelect}
                                label="Side Wall Type"
                                name="Side Wall Type"
                                id="sideWallType"
                            />
                        </div>
                        <div className="mb-4">
                            <InputField
                                name="trailerDescription"
                                label="TRAILER DESCRIPTION "
                                placeholder=""
                                value={get(formValues, "trailerDescription")}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="mb-4">
                            <InputField
                                name="application"
                                label="APPLICATION"
                                placeholder=""
                                value={get(formValues, "application")}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="mb-4">
                            <OrderDropdown
                                options={dropdownOptions?.truckModel ?? []}
                                selectedOption={get(formValues, "truckModel")}
                                onOptionSelect={handleOptionSelect}
                                label="TRUCK MODEL"
                                name="TRUCK MODEL"
                                id="truckModel"
                            />
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-x-8 p-3.5">
                    <div className="grid grid-cols-1 gap-6">
                        <div className="row">
                            <span className="text-xl font-small uppercase">AXLES</span>
                        </div>
                        <div className="grid grid-cols-2 gap-6">
                            <div>
                                <OrderDropdown
                                    options={dropdownOptions?.axlesMake ?? []}
                                    selectedOption={get(formValues, "axlesMake")}
                                    onOptionSelect={handleOptionSelect}
                                    label="Make"
                                    name="Axles Make"
                                    id="axlesMake"
                                />
                                {get(errorMessages, "axlesMake.productName") && <p className="text-red-500 text-[12px] mt-1 text-left">This field in required</p>}
                            </div>
                            <div>
                                <OrderDropdown
                                    options={dropdownOptions?.axlesType ?? []}
                                    selectedOption={get(formValues, "axlesType")}
                                    onOptionSelect={handleOptionSelect}
                                    label="Type"
                                    name="Axles Type"
                                    id="axlesType"
                                />
                                {get(errorMessages, "axlesType.productName") && <p className="text-red-500 text-[12px] mt-1 text-left">This field in required</p>}
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-6">
                        <div className="row">
                            <span className="text-xl font-small uppercase">SUSPENSION</span>
                        </div>
                        <div className="grid grid-cols-3 gap-6">
                            <div>
                                <OrderDropdown
                                    options={dropdownOptions?.suspensionMake ?? []}
                                    selectedOption={get(formValues, "suspensionMake")}
                                    onOptionSelect={handleOptionSelect}
                                    label="Make"
                                    id="suspensionMake"
                                    name="Suspension makeId"
                                />
                                {get(errorMessages, "suspensionMake.productName") && <p className="text-red-500 text-[12px] mt-1 text-left">This field in required</p>}
                            </div>
                            <div>
                                <OrderDropdown
                                    options={dropdownOptions?.suspensionType ?? []}
                                    selectedOption={get(formValues, "suspensionType")}
                                    onOptionSelect={handleOptionSelect}
                                    label="Type"
                                    id="suspensionType"
                                    name="Suspension typeId"
                                />
                                {get(errorMessages, "suspensionType.productName") && <p className="text-red-500 text-[12px] mt-1 text-left">This field in required</p>}
                            </div>
                            <div>
                                <OrderDropdown
                                    options={dropdownOptions?.suspensionCombination ?? []}
                                    selectedOption={get(formValues, "suspensioncombination")}
                                    onOptionSelect={handleOptionSelect}
                                    label="Combination"
                                    id="suspensioncombination"
                                    name="Suspension Combination"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-x-8 p-3.5">
                    <div className="mb-4">
                        {/* //TODO */}
                        <OrderDropdown
                            options={dropdownOptions?.tyreMake ?? []}
                            selectedOption={get(formValues, "tyre")}
                            onOptionSelect={handleOptionSelect}
                            label="TYRE MAKE"
                            id="tyre"
                            name="Tyre makeId"
                        />
                    </div>
                    <div className="grid grid-cols-1 mb-4">
                        <Radio
                            label="TYRE TYPE"
                            name="tyre.radialNylon"
                            value={get(formValues, "tyre.radialNylon")}
                            onChange={handleRadioOptionChange}
                            options={radioOptionsRadialNylon}
                            id="tyre.radialNylon"
                        />
                        {get(errorMessages, "tyre.radialNylon") && <p className="text-red-500 text-[12px] mt-1 text-left">This field in required.</p>}
                        {/* <Radio
                            label="TYRE TYPE"
                            name="tyre.ribLug"
                            value={get(formValues, "tyre.ribLug")}
                            onChange={handleRadioOptionChange}
                            options={radioOptionsRibLug}
                            id="tyre.ribLug"
                        /> */}
                    </div>
                    <div className="mb-4">
                        <OrderDropdown
                            options={dropdownOptions?.wheelRim ?? []}
                            selectedOption={get(formValues, "wheelRim")}
                            onOptionSelect={handleOptionSelect}
                            label="WHEEL RIM"
                            id="wheelRim"
                            name="WheelRim"
                        />
                    </div>
                    <div className="mb-4">
                        <OrderDropdown
                            options={dropdownOptions?.kingpin ?? []}
                            selectedOption={get(formValues, "kingpin")}
                            onOptionSelect={handleOptionSelect}
                            label="KINGPIN"
                            id="kingpin"
                            name="kingpin"
                        />
                    </div>
                    <div className="mb-4">
                        <OrderDropdown
                            options={dropdownOptions?.landingLeg ?? []}
                            selectedOption={get(formValues, "landingLag")}
                            onOptionSelect={handleOptionSelect}
                            label="LANDING LEG"
                            id="landingLag"
                            name="LANDING LEG"
                        />
                    </div>
                    <div className="mb-4">
                        <OrderDropdown
                            options={dropdownOptions?.brakeKit ?? []}
                            selectedOption={get(formValues, "brakeKit")}
                            onOptionSelect={handleOptionSelect}
                            label="BRAKE KIT"
                            id="brakeKit"
                            name="BRAKE KIT"
                        />
                    </div>
                    <div className="mb-4">
                        <OrderDropdown
                            options={dropdownOptions?.brakeChambers ?? []}
                            selectedOption={get(formValues, "brakeChamber")}
                            onOptionSelect={handleOptionSelect}
                            label="Brake Chambers"
                            id="brakeChamber"
                            name="Brake Chambers"
                        />
                    </div>
                    <div className="mb-4">
                        <InputField
                            name="sideMakerTailLamp"
                            label="SIDE MARKER TAIL LAMP"
                            placeholder=""
                            value={get(formValues, "sideMakerTailLamp")}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-4">
                        <InputField
                            name="electrical"
                            label="ELECTRICAL"
                            placeholder=""
                            value={get(formValues, "electrical")}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-4">
                        <OrderDropdown
                            options={dropdownOptions?.twistLock ?? []}
                            selectedOption={get(formValues, "twistLock")}
                            onOptionSelect={handleOptionSelect}
                            label="TWIST LOCK (QTY)"
                            id="twistLock"
                            name="Twist Lock"
                        />
                    </div>
                    <div className="mb-4">
                        <OrderDropdown
                            options={dropdownOptions?.floorSheet ?? []}
                            selectedOption={get(formValues, "floorSheet")}
                            onOptionSelect={handleOptionSelect}
                            label="FLOOR SHEET (Plain Or Chequered)"
                            id="floorSheet"
                            name="FLOOR SHEET"
                        />
                    </div>
                    <div className="mb-4">
                        <OrderDropdown
                            options={dropdownOptions?.tieDownHook ?? []}
                            selectedOption={get(formValues, "tieDownHook")}
                            onOptionSelect={handleOptionSelect}
                            label="Tie Down Hook (QTY)"
                            id="tieDownHook"
                            name="Tie Down Hook"
                        />
                    </div>
                    <div className="mb-4">
                        <OrderDropdown
                            options={dropdownOptions?.additionalReinforcement ?? []}
                            selectedOption={get(formValues, "additionalReinforcement")}
                            onOptionSelect={handleOptionSelect}
                            label="ADDITIONAL REINFORCEMENT (COIL / ANY SPECIFIC LOAD)"
                            id="additionalReinforcement"
                            name="ADDITIONAL REINFORCEMENT"
                        />
                    </div>
                    <div className="mb-4">
                        <OrderDropdown
                            options={dropdownOptions?.coilLashing ?? []}
                            selectedOption={get(formValues, "coilLashing")}
                            onOptionSelect={handleOptionSelect}
                            label="COIL LASHING (QTY)"
                            id="coilLashing"
                            name="COIL LASHING"
                        />
                    </div>
                    <div className="mb-4">
                        <OrderDropdown
                            options={dropdownOptions?.khutlaPocket ?? []}
                            selectedOption={get(formValues, "khutlaPocket")}
                            onOptionSelect={handleOptionSelect}
                            label="KHUTLA POCKET (QTY)"
                            id="khutlaPocket"
                            name="KHUTLA POCKET"
                        />
                    </div>
                    <div className="mb-4">
                        <OrderDropdown
                            options={dropdownOptions?.khutlaPillarDetails ?? []}
                            selectedOption={get(formValues, "khutlaPillarDetails")}
                            onOptionSelect={handleOptionSelect}
                            label="KHUTLA PILLAR DETAILS"
                            id="khutlaPillarDetails"
                            name="KHUTLA PILLAR DETAILS"
                        />
                    </div>
                    <div className="mb-4">
                        <InputField
                            name="supd"
                            label="SUPD"
                            placeholder=""
                            value={get(formValues, "supd")}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-4">
                        <InputField
                            name="rupd"
                            label="RUPD"
                            placeholder=""
                            value={get(formValues, "rupd")}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-4">
                        <InputField
                            name="spareWheelCarrierBkt"
                            label="SPARE WHEEL CARRIER BKT."
                            placeholder=""
                            value={get(formValues, "spareWheelCarrierBkt")}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-4">
                        <InputField
                            name="toolBox"
                            label="TOOL BOX (QTY)"
                            placeholder=""
                            value={get(formValues, "toolBox")}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-4">
                        <OrderDropdown
                            options={dropdownOptions?.mudgaurd ?? []}
                            selectedOption={get(formValues, "mudGuard")}
                            onOptionSelect={handleOptionSelect}
                            label="MUDGUARD (MAKE : STEEL / PP)"
                            id="mudGuard"
                            name="MUDGUARD"
                        />
                    </div>
                    <div className="mb-4">
                        <InputField
                            name="stickers"
                            label="Stickers"
                            placeholder=""
                            value={get(formValues, "stickers")}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-4">
                        <InputField
                            name="spraySuppressor"
                            label="Spray Suppressor"
                            placeholder=""
                            value={get(formValues, "spraySuppressor")}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-4">
                        <InputField
                            name="reflectorYellow"
                            label="REFLECTOR  YELLOW  ( AVERY / 3M)"
                            placeholder=""
                            value={get(formValues, "reflectorYellow")}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-4">
                        <InputField
                            name="note"
                            label="Delivery Date In Words"
                            placeholder=""
                            value={get(formValues, "note")}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-4">
                        <InputField
                            name="painting"
                            label="PAINTING"
                            placeholder=""
                            value={get(formValues, "painting")}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-4">
                        <InputField
                            name="warrenty"
                            label="Warrenty"
                            placeholder=""
                            value={get(formValues, "warrenty")}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-4">
                        <TextAreaField
                            name="additionalInformation"
                            label="Additional Information"
                            placeholder="Enter your description here"
                            value={get(formValues, "additionalInformation")}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="pl-3.5 mb-10">
                    <Button text="Submit" icon="" textColor="white" bgColor="[#39ACE4]" onClick={() => handleSubmit("quotation")} />
                </div>
            </div>}
        </>
    );
};

export default AddQuotationTemplate;
