import React, { useEffect, useState } from "react";
import axios from "axios";
import { get } from "lodash";
import { toast } from "react-toastify";
import { HiOutlineMail } from "react-icons/hi";
import { ColorRing } from "react-loader-spinner";
import { useNavigate, Link } from "react-router-dom";

import { loginServiceApi } from "@/utils/API";
import { 
    INITIAL_VALIDATE_USER_FORM_VALUE,
    VALIDATE_USER_REQUIRED_FIELDS,
    VALIDATE_USER_ERROR_MESSAGE_VALUES,
    DEFAULT_AUTH_CONFIG
} from "../constant";

const ValidateUser = ({ onStepChanged, username }) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [formValue, setFormValue] = useState(INITIAL_VALIDATE_USER_FORM_VALUE);
    const [errorMessages, setErrorMessages] = useState(VALIDATE_USER_ERROR_MESSAGE_VALUES);

    const handleChange = e => {
        setFormValue({
            ...formValue,
            [e.target.name]: e.target.value
        })
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newErrorMessages = {};
        const requiredFields = VALIDATE_USER_REQUIRED_FIELDS;
        requiredFields.forEach((field) => {
            if (!get(formValue, field)) {
                newErrorMessages[field] = "This field is required.";
            } else {
                newErrorMessages[field] = "";
            }
        });

        setErrorMessages(newErrorMessages);
        if (Object.values(newErrorMessages).some((errorMsg) => errorMsg !== "")) {
            return;
        };

        try {
            setLoading(true);
            let url = `${loginServiceApi.resetPasswordSendOTP}?userID=${formValue.username}`;
            let response = await axios.patch(url, {}, DEFAULT_AUTH_CONFIG);
            setLoading(false);

            toast.success("OTP has been sent to registered email");
            onStepChanged("VALIDATE_OTP", formValue.username);
        } catch (error) {
            setLoading(false);
            toast.error("Error, Please try again!");
        }
    };

    return (
        <>
            <h1 className="text-white text-3xl font-semibold text-center mb-2">Reset Password</h1>
            <form className="space-y-6">
                <div className="flex items-center rounded-md bg-white border border-gray-300">
                    <span className="inline-flex items-center justify-center rounded-l-md px-2">
                        <HiOutlineMail className="w-6 h-6 text-gray-400" />
                    </span>
                    <input
                        type="username"
                        id="username"
                        name="username"
                        className="flex-grow w-full px-4 py-2 text-gray-700 placeholder-gray-500 border-gray-300 rounded-r-md focus:outline-none"
                        placeholder="Enter Username"
                        value={formValue.username}
                        onChange={handleChange}
                        required
                    />
                </div>
                {errorMessages.username && <p className="text-red-500 text-left text-[12px]">{errorMessages.username}</p>}
                {!loading && <button
                    className="w-full bg-[#FF9220] hover:bg-orange-500 text-white font-bold py-2 px-2 rounded-lg h-12"
                    onClick={handleSubmit}
                    type="submit"
                >
                    Submit
                </button>}
                {loading && <div className="grid justify-items-center">
                    <ColorRing
                        visible={loading}
                        height="80"
                        width="80"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper text-center"
                        colors={["#FF9220", "#FF9220", "#FF9220", "#FF9220", "#FF9220"]}
                    />
                </div>}
            </form>
            {!loading && 
            <p className="text-center text-white text-sm mt-4">
                <Link to="/login">
                    Back
                </Link>
            </p>}
        </>
    );
}

export default ValidateUser;
