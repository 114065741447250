import React, { useEffect, useState } from "react";
import { isEmpty, cloneDeep, set } from "lodash";
import { useNavigate } from "react-router-dom";
import { Blocks } from "react-loader-spinner";
import { TrashIcon, PencilIcon, EyeIcon, BarsArrowUpIcon } from "@heroicons/react/24/solid";

import PaginationTable from "@/components/PaginationTable";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal";

import { APIService } from "@/services";
import { orderApi } from "@/utils/API";
import { addSerialNumbersToList } from "@/utils/CustomFunctions";
import { WORK_ORDER_LIST_HEADER } from "./Headers";
import { APP_URLS } from "../../../routes";

const headerButton = {
    showAdd: true,
    showExportBtn: true
};

const WorkOrderList = ({ tabIndex, showAction }) => {
    const navigate = useNavigate();
    const [workOrderList, setWorkOrderList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [isOpenHoldModal, setIsOpenHoldModal] = useState(false);
    const [isOpenApproveModal, setIsOpenApproveModal] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [orderPlaceListChanged, setOrderPlaceListChanged] = useState(false);
    const [orderPayload, setOrderPayload] = useState(null);
    const [selectedId, setSelectedId] = useState("");

    const resetOrderPayload = () => {
        setSelectedId('');
        setOrderPayload(null);
    }

    const toggleHoldPopup = () => {
        setIsOpenHoldModal(!isOpenHoldModal);
        resetOrderPayload();
    };
    
    const toggleApprovePopup = () => {
        setIsOpenApproveModal(!isOpenApproveModal);
        resetOrderPayload();
    };

    const toggleDeletePopup = () => {
        setIsOpenDeleteModal(!isOpenDeleteModal);
        resetOrderPayload();
    };

    const getData = async () => {
        let status = "", filters = "";
        if (tabIndex === 0) {
            status = "Initial";
            filters = `?filterAnd=parentOrder%7Cisnull%26status%7Ceq%7C${status}&limit=100000&offset=0&order=DESC&orderBy=lastUpdated`;
        } else if (tabIndex === 1) {
            status = "Approved";
            filters = `?filterAnd=parentOrder%7Cisnull%26status%7Ceq%7C${status}&limit=100000&offset=0&order=DESC&orderBy=lastUpdated`;
        } else if (tabIndex === 2) {
            status = "Hold";
            filters = `?filterAnd=parentOrder%7Cisnull%26status%7Ceq%7C${status}&limit=100000&offset=0&order=DESC&orderBy=lastUpdated`;
        }

        setIsLoading(true);
        const response = await APIService.get(orderApi.filter + filters);
        const updatedList = await addSerialNumbersToList(response.orderList);
        setWorkOrderList(updatedList);
        setIsLoading(false);
    };

    useEffect(() => {
        getData();
    }, [orderPlaceListChanged]);

    const handleDelete = async (id) => {
        setIsPending(true);
        const deleteUrl = `${orderApi.delete}${id}`;
        //const response = await APIService.delete(deleteUrl);

        // resetOrderPayload();
        // setIsOpenDeleteModal(!isOpenDeleteModal);
        // setOrderPlaceListChanged(!orderPlaceListChanged);
        // setIsPending(false);
    };

    const handleApprove = async (id) => {
        setIsPending(true);
        const response = await APIService.patch(`${orderApi.patch}/${id}`, { status: "Approved" });

        resetOrderPayload();
        setIsOpenApproveModal(!isOpenApproveModal);
        setOrderPlaceListChanged(!orderPlaceListChanged);
        setIsPending(false);
    };

    const handleHold = async (id) => {
        setIsPending(true);
        const response = await APIService.patch(`${orderApi.patch}/${id}`, { status: "Hold" });

        resetOrderPayload();
        setIsOpenHoldModal(!isOpenHoldModal);
        setOrderPlaceListChanged(!orderPlaceListChanged);
        setIsPending(false);
    };

    const handleActionClick = async (action, payload) => {
        setOrderPayload(payload)
        setSelectedId(payload.id);

        if (action === "delete") {
            setIsOpenDeleteModal(!isOpenDeleteModal);
        } else if (action === "approve") {
            setIsOpenApproveModal(!isOpenApproveModal)
        } else if (action === "hold") {
            setIsOpenHoldModal(!isOpenHoldModal);
        }
    };

    const viewSubOrders = (rowData) => {
        navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.SALE_VIEW_WO_LIST}?workOrderId=${rowData.id}&quotationId=${rowData.quotationId}`);
    };

    const viewOrderDetails = (rowData) => {
        navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.VIEW_WO}?quotationId=${rowData.quotationId}&workOrderId=${rowData.id}&orderType=0&mode=view`);
    };

    const editOrder = (rowData) => {
        navigate(`${APP_URLS.APP_ROOT}/${APP_URLS.EDIT_WO}?quotationId=${rowData.quotationId}&workOrderId=${rowData.id}&orderType=0`);
    }; 

    let columnsArray = [
        ...WORK_ORDER_LIST_HEADER,
        {
            Header: "Action",
            accessor: "action",
            width: 5,
            Cell: ({ value, row }) => (
                <div className="text-[25] flex justify-center text-[#39ACE4]">
                    <EyeIcon title="View Order Details" className="w-5 h-5 text-[#39ACE4] m-1 cursor-pointer mt-4" onClick={() => viewOrderDetails(row.original) } />
                    <PencilIcon title="Edit Order" className="w-5 h-5 text-[#39ACE4] m-1 cursor-pointer mt-4" onClick={() => editOrder(row.original) } />
                    { (tabIndex !==0 && tabIndex !==2) && <BarsArrowUpIcon title="View Sub Orders" className="w-5 h-5 text-[#39ACE4] m-1 cursor-pointer mt-4" onClick={() => viewSubOrders(row.original) } /> }
                    { showAction && <>
                        <button className="cursor-pointer pill-button success text-center inline-block border-4" onClick={() => handleActionClick("approve", row.original)}>Approve</button>
                        <button className="cursor-pointer pill-button danger text-center inline-block border-4" onClick={() => handleActionClick("hold", row.original)}>Hold</button> 
                    </>}
                </div>
            ),
        },
    ];

    return (
        <>
            <div className="flex flex-col rounded h-auto bg-white">
                {isOpenDeleteModal && selectedId && (
                    <DeleteConfirmationModal
                        handleDelete={handleDelete}
                        isOpen={isOpenDeleteModal}
                        togglePopup={toggleDeletePopup}
                        id={selectedId}
                        showLoader={isPending}
                    />
                )}

                {isOpenApproveModal && selectedId && (
                    <DeleteConfirmationModal
                        handleDelete={handleApprove}
                        isOpen={isOpenApproveModal}
                        togglePopup={toggleApprovePopup}
                        id={selectedId}
                        placeholderText="Are you sure to make approve this work order?"
                        showLoader={isPending}
                    />
                )}

                {isOpenHoldModal && selectedId && (
                    <DeleteConfirmationModal
                        handleDelete={handleHold}
                        isOpen={isOpenHoldModal}
                        togglePopup={toggleHoldPopup}
                        id={selectedId}
                        placeholderText="Are you sure to make hold this work order?"
                        showLoader={isPending}
                    />
                )}

                {isLoading ? (
                    <div className="flex items-center justify-center">
                        <Blocks
                            height="80"
                            width="80"
                            color="#4fa94d"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            visible={true}
                        />
                    </div>
                ) : (
                    workOrderList && <PaginationTable columnsData={columnsArray} rowData={workOrderList} />
                )}
            </div>
        </>
    )
}

export default WorkOrderList;
