import axios from "axios";
import { toast } from "react-toastify";
import { API_HOST_URI } from "../utils/API";

// set defaut headers
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = API_HOST_URI;

axios.interceptors.request.use(config => {
        const token = sessionStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error =>  Promise.reject(error)
);

axios.interceptors.response.use(
    response => response,
    error => {
        console.log("Request failed:", error?.response?.data);
        handleError(error?.response?.data);
        return Promise.reject(error);
    }
);

const APIService = {
    get: async (endpoint) => {
        try {
            const response = await axios.get(`${endpoint}`);
            return response.data;
        } catch (error) {
            console.log("Error:", error);
        }
    },
    post: async (endpoint, payload) => {
        try {
            const response = await axios.post(`${endpoint}`, payload);
            return {
                data: response.data,
                status: response.status
            }
        } catch (error) {
            console.log("Error:", error);
        }
    },
    delete: async (endpoint) => {
        try {
            return await axios.delete(`${endpoint}`);
        } catch (error) {
            console.log("Error:", error);
        }
    },
    patch: async (endpoint, data) => {
        try {
            const response = await axios.patch(`${endpoint}`, data);
            return {
                data: response.data,
                status: response.status
            }
        } catch (error) {
            console.log("Error:", error);
        }
    },
};

const handleError = (error) => {
    if (error?.httpStatus === 400) {
        toast.error("Bad request");
    } else if (error?.httpStatus === 401) {
        toast.error("Unauthorized request");
    } else {
        toast.error("Internal server error");
    }
};

export default APIService;
