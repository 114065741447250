import React from 'react';
import { FaSearch } from 'react-icons/fa';

const SearchInput = ({ placeholder, filterDataFunction = () => {} }) => {
  return (
    <div className="relative">
      <input
        placeholder='Search'
        className="w-full outline-0 px-4 py-1.5 pr-10 rounded border border-gray-400 text-[12px] text-[#8B8D94] outline-0 placeholder:italic"
        type="text"
        onChange={(e) => {filterDataFunction(e)}}
      />
      <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
        <FaSearch className="text-gray-400" color='gray' />
      </div>
    </div>
  );
};

export default SearchInput;
