import { createContext, useState, useEffect } from "react";

import { useAuth } from "@/hooks/useAuth";
import { organizationApi, getOrderDataApi } from "@/utils/API";
import { getDropdownOptions } from "@/utils/CustomFunctions";
import { APIService } from "@/services";
import { APP_URLS } from "@/routes";

export const DataContext = createContext();

const DataProvider = ({ children }) => {

    const { user } = useAuth()
    const [data, setData] = useState({});
    const [isFetching, setIsFetching] = useState(false);

    const fetchData = async () => {
        setIsFetching(true);
        const orderformResponse = await APIService.get(getOrderDataApi.get);
        const orgResponse = await APIService.get(`${organizationApi.get}`);
        const orderForm = await getDropdownOptions(orderformResponse);
        const data = { orderForm, organization: orgResponse };
        setData((prevState) => ({
            ...prevState,
            ...data
        }));
        setIsFetching(false);
    };

    const refreshData = async () => {
        await fetchData();
    }

    useEffect(() => {
        if (user) {
            fetchData();
        }
    }, [user]);

    return (
        <DataContext.Provider value={{ data, isFetching, refreshData }}>
            {children}
        </DataContext.Provider>
    )
};

export default DataProvider
