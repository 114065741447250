import PropTypes from "prop-types";
import Modal from "react-modal";
import { ColorRing } from "react-loader-spinner";
import Button from "../Button";

Modal.setAppElement("#root");

const DeleteConfirmationModal = ({ id, togglePopup, isOpen, handleDelete, placeholderText = "Are you sure want to delete ?", showLoader = false }) => {

    const handleSubmit = async (id) => {
        handleDelete(id);
    };

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={togglePopup}
                contentLabel="Example Modal"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                className="bg-white p-6 rounded shadow-lg"
            >
                <div className="p-3.5">
                    <span className="text-[14px] font-small pt-3.5">{placeholderText}</span>
                </div>
                
                <div className="flex gap-2 mt-5 justify-end">
                    {!showLoader && <>
                        <Button text="No" textColor="Black" bgColor="White" onClick={() => togglePopup()} />
                        <Button text="Yes" textColor="white" bgColor="[#39ACE4]" onClick={() => handleSubmit(id)} />
                    </>}
                    {showLoader && <ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={["#39ACE4","#39ACE4","#39ACE4","#39ACE4","#39ACE4" ]}
                    />}
                </div>
            </Modal>
        </div>
    );
};

DeleteConfirmationModal.propTypes = {
    id: PropTypes.string,
    togglePopup: PropTypes.func,
    isOpen: PropTypes.bool,
    handleDelete: PropTypes.func,
    placeholderText: PropTypes.string
};

export default DeleteConfirmationModal;
