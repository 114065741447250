import React from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { ArrowUpIcon, ArrowDownIcon  } from "@heroicons/react/24/solid";

import "./PaginationTable.css"

const icon = {
    className: "w-2 h-2 text-[#39ACE4] inline ml-1 cursor-pointer"
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
    // When using the useFlexLayout:
    minWidth: 30, // minWidth is only used as a limit for resizing
    width: 70, // width is used for both the flex-basis and flex-grow
    maxWidth: 200,
}

// Be sure to pass our updateMyData and the skipPageReset option
function Table({ columns, data, updateMyData, skipPageReset }) {
    // For this example, we're using pagination to illustrate how to stop
    // the current page from resetting when our data changes
    // Otherwise, nothing is different here.
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            // use the skipPageReset option to disable page resetting temporarily
            autoResetPage: !skipPageReset,
            // updateMyData isn't part of the API, but
            // anything we put into these options will
            // automatically be available on the instance.
            // That way we can call this function from our
            // cell renderer!
            updateMyData,
            showHeader: false
        },
        useSortBy,
        usePagination
    )

    // Render the UI for your table
    return (
        <>
            <table {...getTableProps()} className="w-full min-w-[640px] table-auto border">
                <thead className="bg-[#EAEDF7]">
                    {headerGroups.map((headerGroup, hIndex) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={`header${hIndex}`}>
                            {headerGroup.headers.map((column, hCellIndex) => (
                                <th
                                    className="p-2"
                                    {...column.getHeaderProps(column.getSortByToggleProps())} // Add sort props here
                                    style={{ width: column.width }}
                                    key={`header${hCellIndex}`}
                                >
                                    <span className="text-[11px] text-[#8B8D94] text-center antialiased font-bold">
                                        {column.render("Header")}
                                        {column.isSorted ? (column.isSortedDesc ? <ArrowDownIcon {...icon} title="Descending" /> : <ArrowUpIcon {...icon} title="Ascending" />) : ""}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} className="h-8 border-b" key={`row${rowIndex}`}>
                                {row.cells.map((cell, cellIndex) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            className="p-2"
                                            style={{ width: "5px !important" }}
                                            key={`cell${cellIndex}`}
                                        >
                                            <span className="block text-[10px] text-[#8B8D94] text-center">
                                                {cell.column.id === "serialNo" ? (
                                                    rowIndex + 1
                                                ) : (cell.column.Header === "Date" || cell.column.id === "createdOn") ? (
                                                    <>
                                                        {/* Format the date as DD/MM/YYYY */}
                                                        {cell.value === null ? "" : new Date(cell.value).toLocaleDateString("en-GB")}
                                                    </>
                                                ) : (
                                                    cell.render("Cell")
                                                )}
                                            </span>
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="flex justify-end mt-4">
                <button
                    className="inline-flex items-center px-2 text-sm font-medium text-[#39ACE4] hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                >
                    {'<<'}
                </button>
                <div className="flex items-center justify-center">
                    <span className="text-center p-2 text-[14px] text-[#39ACE4]">
                        Page
                        <strong className="ml-1">
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>
                    </span>
                </div>

                <button
                    className="inline-flex items-center px-2 text-sm font-medium text-[#39ACE4] hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                >
                    {">>"}
                </button>
                <select
                    className="text-[14px] text-[#39ACE4]"
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </>

    )
}

function PaginationTable({ columnsData, rowData }) {

    const columns = React.useMemo(() => columnsData, []);
    const [tableData, setTableData] = React.useState(rowData.data ?? rowData);
    const [originalData] = React.useState(tableData);
    const [skipPageReset, setSkipPageReset] = React.useState(false);

    const updateMyData = (rowIndex, columnId, value) => {
        setSkipPageReset(true);
        setTableData(old =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            })
        );
    };

    React.useEffect(() => {
        setSkipPageReset(false);
    }, [tableData]);

    React.useEffect(() => {
        setTableData(rowData.data ?? rowData); // Update tableData when rowData changes
    }, [rowData]);

    const resetData = () => {
        setTableData(originalData);
    };

    return (
        <div className="p-3.5 flex flex-row overflow-auto">
            <button onClick={resetData} style={{ display: "none" }}>Reset Data</button>
            <div className="table-container" style={{ width: "100%" }}>
                {/* <CustomScrollbarWrapper
                    style={{ width: '100%', height: 'calc(50vh - 32px)' }}
                    customClass={"custom-scrollbar-thumb-table"}
                > */}

                {tableData.length > 0 ?
                    <Table
                        columns={columns}
                        data={tableData}
                        updateMyData={updateMyData}
                        skipPageReset={skipPageReset}
                    /> : <span>No Record Found</span>}
                {/* </CustomScrollbarWrapper> */}
            </div>
        </div>
    )
}

export default PaginationTable;
